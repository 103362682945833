import api from '@/api'
import api_ls from '@/api/index1'
import { auth, authAll, getImgPreviewPath, inputErrorScroll } from '@/util'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import Cookies from 'js-cookie'
import hotkeys from 'hotkeys-js'
import mitt from 'mitt'
import zhT from './zh-t'

export default function globalProperties(app) {
    // 请求
    app.config.globalProperties.$api = api
    app.config.globalProperties.$api_ls = api_ls
    // 鉴权
    app.config.globalProperties.$auth = auth
    app.config.globalProperties.$authAll = authAll
    // 语言
    app.config.globalProperties.$t = zhT
    dayjs.locale('zh-cn')
    app.config.globalProperties.$dayjs = dayjs
    app.config.globalProperties.$cookies = Cookies
    app.config.globalProperties.$hotkeys = hotkeys
    app.config.globalProperties.$eventBus = mitt()
    app.config.globalProperties.$message = ElMessage
    app.config.globalProperties.$confirm = ElMessageBox.confirm
    // 获取图片预览地址
    app.config.globalProperties.$getImgPreviewPath = getImgPreviewPath
    // 输入错误滚动到指定位置
    app.config.globalProperties.$inputErrorScroll = inputErrorScroll

}
