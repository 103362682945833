/*
* 去除特殊字符
*/
export function checkSpecialKey(str) {
    let specialKey =
      '[`~!#$^&*()=|{}\'\'\\[\\]<>/?#￥……&*——|{}【】‘；：”“\']‘\''
    for (let i = 0; i < str.length; i++) {
        if (specialKey.indexOf(str.substr(i, 1)) != -1) {
            return false
        }
    }
    return true
}
/**
 * URL地址
 * @param {*} s
 */
export function isURL(s) {
    return /^http[s]?:\/\/.*/.test(s)
}
