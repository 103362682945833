import axios from 'axios'
// import qs from 'qs'
import router from '@/router/index'
import { ElMessage } from 'element-plus'
import useUserStore from '@/store/modules/user'

const toLogin = () => {
    useUserStore().logout().then(() => {
        router.push({
            name: 'ddlogin',
            query: {
                redirect: router.currentRoute.value.path !== '/ddlogin' ? router.currentRoute.value.fullPath : undefined
            }
        })
    })
}

const api = axios.create({
    baseURL: import.meta.env.DEV && import.meta.env.VITE_OPEN_PROXY === 'true' ? '/proxy/' : import.meta.env.VITE_APP_API_BASEURL,
    timeout: 60000,
    responseType: 'json'
})

api.interceptors.request.use(
    request => {
        const userOutsideStore = useUserStore()
        /**
         * 全局拦截请求发送前提交的参数
         * 以下代码为示例，在请求头里带上 token 信息
         */

        if (request.method == 'post') {
            request.headers.authorization = userOutsideStore.token
            if (request.data instanceof FormData) {
                if (userOutsideStore.isLogin) {
                    // 如果是 FormData 类型（上传图片）
                    request.data.append('authorization', userOutsideStore.token)
                }
            } else {
                console.log(typeof request.data)
                if (typeof request.data === 'string') { // 兼容pc系统post传字符串
                    request.headers['Content-Type'] = 'application/json; charset=utf-8'
                } else {
                    // 带上 token
                    if (request.data == undefined) {
                        request.data = {}
                    }
                    if (userOutsideStore.isLogin) {
                        if (Object.prototype.toString.call(request.data) == '[object Number]') {
                            request.headers['Content-Type'] = 'application/json; charset=utf-8'
                            request.data = request.data + ''
                        } else {
                            request.data.authorization = userOutsideStore.token
                        }
                    }
                }
                // request.data = Qs.stringify(request.data)
            }
        } else {
            // blob
            if (request.method == 'put') {
                console.log(request)
                if (request.url == '/p/orderRefund/cancel') {
                    request.headers['Content-Type'] = 'application/json; charset=utf-8'
                }
            }
            if (request.params && request.params.type) {
                request.headers.responseType = 'blob'
                delete request.params.type
            }
            // 带上 token
            if (request.params == undefined) {
                console.log('request.params:', request.params)
                request.params = {}
            }
            if (userOutsideStore.isLogin) {
                request.headers.authorization = userOutsideStore.token
            }
        }

        return request
    }
)

api.interceptors.response.use(
    response => {
        /**
         * 全局拦截请求发送后返回的数据，如果数据有报错则在这做全局的错误提示
         * 假设返回数据格式为：{ status: 1, error: '', data: '' }
         * 规则是当 status 为 1 时表示请求成功，为 0 时表示接口需要登录或者登录状态失效，需要重新登录
         * 请求出错时 error 会返回错误信息
         */
        // 如果接口请求时发现 token 失效，则立马跳转到登录页
        const userOutsideStore = useUserStore()
        if (response.data.code == 403 || response.data.code == 401 || response.data.code == 407) {
            userOutsideStore.logout()
            toLogin()
        } else {
            if (response.data.code == 475) {
                ElMessage.error('请先在手机端授权使用')
                return
            }
            if (response.data.code == 500 && response.data.message != '') {
                ElMessage.error(response.data.message)
                return Promise.reject(response.data)
            }
            return Promise.resolve(response.data)
        }
    },
    error => {
        if (error.response.status == 475) {
            ElMessage.error('请先在手机端授权使用')
            return
        }
        let message = error.message
        if (error.response.status == 400) {
            ElMessage.error(error.response.data)
            return Promise.reject(error)
        } else if (message == 'Network Error') {
            message = '后端网络故障'
        } else if (message.includes('timeout')) {
            message = '接口请求超时'
        } else if (message.includes('Request failed with status code')) {
            message = '接口' + message.substr(message.length - 3) + '异常'
        }
        ElMessage({
            message,
            type: 'error'
        })
        return Promise.reject(error)
    }
)

export default api
