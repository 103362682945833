// import wx from 'weixin-js-sdk'
import { ElMessage } from 'element-plus'
function qw_ready() {
    wx.ready(function() {
        return true
    })
    wx.error(function(err) {
        ElMessage('调用失败', err)
        return false
    })
}

const qwApi = {
    AuthConfig: (item, fn) => {
        console.log('鉴权开始')
        console.log(item, '鉴权拿到的数据')
        if (qw_ready) {
            wx.agentConfig({
                corpid: item.corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
                agentid: item.agentid, // 必填，企业微信的应用id （e.g. 1000247）
                timestamp: item.timestamp, // 必填，生成签名的时间戳
                nonceStr: item.nonceStr, // 必填，生成签名的随机串
                signature: item.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
                jsApiList: [
                    'selectExternalContact',
                    'selectPrivilegedContact',
                    'selectEnterpriseContact',
                    'sendChatMessage',
                    'previewFile'
                ], // 必填，传入需要使用的接口名称
                success: function(res) {
                    console.log('鉴权成功')
                    fn(res)
                },
                fail: function(err) {
                    fn(err)
                    if (err.errMsg.indexOf('function not exist') > -1) {
                        ElMessage('版本过低请升级')
                    }
                }
            })
        }
    },
    config: item => {
        if (qw_ready) {
            wx.config({
                beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: item.corpid, // 必填，企业微信的corpID
                timestamp: item.timestamp, // 必填，生成签名的时间戳
                nonceStr: item.nonceStr, // 必填，生成签名的随机串
                signature: item.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
                jsApiList: [] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
            })
        }
    },
    selectEnterpriseContact: item => { // 选人接口
        return new Promise((resolve, reject) => {
            wx.invoke('selectEnterpriseContact', {
                'fromDepartmentId': item.fromDepartmentId || '-1', // 必填，表示打开的通讯录从指定的部门开始展示，-1表示自己所在部门开始, 0表示从最上层开始
                'mode': item.multiple ? 'multi' : 'single', // 必填，选择模式，single表示单选，multi表示多选
                'type': ['user'], // 必填，选择限制类型，指定department、user中的一个或者多个
                'selectedUserIds': item.users || [] // 非必填，已选用户ID列表。用于多次选人时可重入，single模式下请勿填入多个id
            }, res => {
                if (res.err_msg == 'selectEnterpriseContact:ok') {
                    if (typeof res.result === 'string') {
                        res.result = JSON.parse(res.result)
                    }
                    resolve({
                        result: res.result,
                        type: 'qywx_pc'
                    })
                }
            })
        })
    }
}
export default qwApi
