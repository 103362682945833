import storage from '@/util/storage'
import dd from 'dingtalk-jsapi'

export function isOpenMode() {
    var ua = window.navigator.userAgent.toLowerCase()
    // 平台、设备和操作系统
    var system = {
        win: false,
        mac: false,
        xll: false,
        ipad: false
    }
    // 检测平台
    var p = navigator.platform
    system.win = p.indexOf('Win') == 0
    system.mac = p.indexOf('Mac') == 0
    system.x11 = (p == 'X11') || (p.indexOf('Linux') == 0)
    system.ipad = (navigator.userAgent.match(/iPad/i) != null)
    // 跳转语句，如果是手机访问就自动跳转到wap.baidu.com页面
    if (system.win || system.mac || system.xll || system.ipad) {
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            // alert('在PC端微信上打开的')
            return 2
        } else if (dd.env.platform == 'pc') {
            // alert('在PC端钉钉打开')
            return 3
        } else {
            // alert('在普通pc打开')
            return 5
        }
    } else {
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            // alert('在手机端微信上打开的')
            return 1
        } else if (dd.env.platform == 'notInDingTalk') {
            // alert('在非手机钉钉打开的')
            return 4
        } else {
            // alert('其他')
            return 6
        }
    }
}
export function memberIs() {
    const ua = window.navigator.userAgent.toLowerCase()
    if (navigator.userAgent.indexOf('DingTalk') !== -1) { // 钉钉客户端
        return 'DingTalk'
    } else if ((ua.match(/micromessenger/i) == 'micromessenger') && (ua.match(/wxwork/i) == 'wxwork')) { // 企微客户端
        return 'jwxwork'
    } else {
        return 'browser' // 普通浏览器
    }
}
export function getPlatform() {
    return storage.session.get('platform') || 'SAAS_HR'
}
