const zh = {
    'language': 'zh',
    'searchByStOrpN': '搜索店铺或者商品名称',
    'classification': '分类',
    'sort': '排序',
    'sales': '销量',
    'quantity': '数量',
    'price': '价格',
    'default': '默认',
    'all': '全部',
    'onlyLeft': '仅剩',
    'distance': '距',
    'start': '开始',
    'end': '结束',
    'sorryNoRelatedActivities': '很抱歉，没有相关活动',
    'sorryNoRelatedProducts': '很抱歉，没有相关商品',
    'backToPreviousPage': '返回上一页',
    'lookAtOther': '看看别的',
    'backToHome': '回到首页',
    'discountCoupon': '优惠券',
    'vouchers': '领劵',
    'full': '满',
    'less': '减',
    'yuan': '￥',
    'enjoy': '享',
    'fold': '折',
    'more': '更多',
    'promotion': '促销',
    'buyNow': '立即购买',
    'tips': '提示',
    'success': '成功',
    'total': '共',
    'items': '件',
    'purchaseRestrictions': '限购',
    'dayRi': '日',
    'week': '周',
    'month': '月',
    'season': '季',
    'year': '年',
    'pendingPayment': '待支付',
    'pendingDelivery': '待发货',
    'pendingReceipt': '待收货',
    'pendinEvaluation': '待评价',
    'evaluated': '已评价',
    'shipped': '已发货',
    'completed': '已完成',
    'canceled': '已取消',
    'grouping': '拼团中',
    'delete': '删除',
    'cancel': '取消',
    'products': '商品',
    'priceOne': '单价',
    'subtotal': '小计',
    'totalAll': '总额',
    'status': '状态',
    'time': '时间',
    'day': '天',
    'day1': '天',
    'time1': '时',
    'minutes': '分',
    'seconds': '秒',
    'operation': '操作',
    'orderNumberT': '订单号',
    'determine': '确定',
    'available': '可用',
    'releaseTime': '发布时间',
    'yimaHeadlines': '商城头条',
    'storeAnnouncement': '店铺公告',
    'submit': '提交',
    'imageNoformatUpload': '上传图片格式不正确，请重新上传',
    'imageSizeCannotExceed2MB': '上传图片大小不能超过 2MB!',
    'only': '仅限',
    'loading': '加载中...',
    'inputAllSpace': '内容不能全为空格',

    'login': {

        'accountLogin': '账号登录',
        'mobileLogin': '手机登录',
        'username': '账号',
        'enterUsername': '请输入手机号/用户名',
        'enterUserNumber': '请输入手机号',
        'accountRestrictions': '账号为4~16位字母、数字或下划线',
        'usernameWarn': '请输入账号',
        'password': '密码',
        'enterPassword': '请输入密码',
        'pleaseAgreefollowingTerms': '请同意下方条款',
        'changePassword': '修改密码',
        'notUsername': '还没有账号？',
        'registeredUsername': '去注册',
        'phoneNumber': '手机号',
        'enterPhone': '请输入登录手机号',
        'phoneWarn': '手机号格式错误',
        'verificationCode': '验证码',
        'enterCodeFirst': '请先获取验证码',
        'getCode': '获取验证码',
        'enterCode': '请输入验证码',
        'newPassword': '新密码',
        'enterNew': '请输入新密码',
        'confirmNew': '确认密码',
        'confirmNewEmpty': '确认密码不能为空',
        'enterNewAgain': '请再次输入新密码',
        'comparedPassword': '确认密码与新密码不一致',
        'login': '登录',
        'consentOfLoginRepresentative': '登录代表同意',
        'consentOfRegisteredRepresentative': '注册代表同意',
        'termsOfService': '《服务条款》',
        'privacyPolicy': '《隐私策略》',
        'and': '和',
        'loginSuccessful': '登录成功!',
        'backHomepage': '回到首页',
        'confirmChanges': '确认修改',
        'BackLogin': '返回登录',
        'pwdCantBeEmpty': '密码不能为空',
        'forgotYourPassword': '忘记密码?',
        'notRegisteredYet': '还没注册？'

    },

    'register': {
        'registerNow': '立即注册',
        'registerAccount': '注册账号',
        'enterValidPhone': '请输入正确的手机号',
        'haveAnAccount': '已有账号?',
        'verifyCellPhoneNumber': '验证手机号',
        'fillInTheAccountInformation': '填写账号信息',
        'completeRegistration': '完成注册',
        'goToLogin': '去登录',
        'nextStep': '下一步',
        'setAccountNumber': '设置账号',
        'pleaseSetAccount': '请您设置账号/用户名',
        'setAccountNumberTips': '账号由长度4~16位的字母、数字和下划线组成',
        'setPassword': '设置密码',
        'pleaseSetPassword': '请您修改密码',
        'pleaseSetYourPassword': '请您设置密码',
        'enterThePasswordAgain': '再次输入密码',
        'passwordVerification': '密码由字母加数字或符号至少两种以上字符组成6-20位半角字符，区分大小写',
        'confirmPasswordAgain': '请再次确认密码',
        'ConfirmPasswordNot': '确认密码与密码不一致',
        'registrationSuccessful': '恭喜你，注册成功！',
        'autoJumpToHome': '后自动跳转到首页',
        'setNickName': '设置昵称',
        'pleaseSetNickName': '请您设置昵称'
    },

    'commonHead': {
        'login': '登录',
        'registration': '注册',
        'membershipCentre': '会员中心',
        'myCollection': '我的收藏',
        'logout': '退出登录',
        'mallHome': '商城首页',
        'home': '首页',
        'myShop': '我的店铺',
        'freeShop': '免费开店',
        'myOrder': '我的订单',
        'personalCenter': '个人中心',
        'mobileMall': '手机商城',
        'cellPhoneScanCodeExperience': '手机扫码体验',
        'shopCart': '购物车',
        'switchingLanguages': 'English',

        'productList': '商品列表',
        'newProducts': '新品推荐',
        'limitedTimeOffer': '限时特惠',
        'groupDiscount': '优惠团购',
        'couponPooling': '优惠券凑单',
        'spikeSpecial': '秒杀专场',
        'voucherCenter': '领劵中心',
        'productNameCannotBeEmpty': '商品名称不能为空',
        'storeNameCannotBeEmpty': '店铺名称不能为空',
        'sellersCentre': '卖家中心',
        'merchantPlatform': '商家后台'

    },
    'commonFoot': {

        'scanTheCodeToFollowUs': '扫码关注我们',
        'serviceGuarantee': '服务保障',
        'genuineGuarantee': '正品保证',
        'sevenDayreturn': '7天无理由退换',
        'sevenDayservice': '7*8小时客户服务',
        'paymentMethod': '支付方式',
        'onlinePayment': '线上支付',
        'cashOnDelivery': '货到付款',
        'offlinePayment': '线下付款',
        'memberServices': '会员服务',
        'freeRegistration': '免费注册',
        'retrievePassword': '找回密码',
        'logisticsServices': '物流服务',
        'freeShippingOnProducts': '商品免邮',
        'courierDelivery': '快递配送',
        'officialWebsite': '官方网站',
        'openSourceWebsite': '开源官网'
    },

    'categroyCommon': {
        'categoryFilter': '全部商品分类',
        'h5Mall': 'H5商城'
    },

    'pagination': {
        'previousPage': '上一页',
        'nextPage': '下一页',
        'goTo': '到第',
        'page': '页',
        'total': '共'
    },

    'index': {
        'search': '搜索',
        'prod': '商品',
        'shop': '店铺',
        'shoppingCart': '购物车',
        'popularSearches': '热门搜索',
        'newProduct': '新品推荐',
        'limitedTimeOffer': '限时特惠',
        'groupDiscount': '优惠团购',
        'recommendGoodThingsTogetherWithTheGroup': '口碑好店，无限回购',
        'recommendGoodThings': '好物推荐，一起拼团',
        'secondKillZone': '秒杀专区',
        'selectedProductsLimited': '精选商品，限时秒杀',
        'coupons': '领券',
        'couponsCenter': '领劵中心',
        'spike': '秒杀',
        'specialSale': '秒杀专场',
        'moreSpikes': '更多秒杀',
        'groupBuy': '团购',
        'moreGroupBuy': '更多拼团',
        'join': '人团',
        'seeMore': '查看更多',
        'hotSale': '商城热销',
        'wordGoodiesInfiniteRepurchase': '口碑好物，无限回购',
        'preferredStores': '优选好店',
        'peopleToFollow': '人关注',
        'goIn': '进店看看',
        'allLoaded': '已加载所有商品',
        'group': '拼团'
    },

    'prodDetail': {

        'productDetails': '商品详情',
        'selfEmployed': '自营',
        'selfOperatedStores': '自营店铺',
        'collectionStores': '收藏店铺',
        'collectedStores': '已收藏店铺',
        'specialZone': '专区',
        'deadline': '截止',
        'preSale': '预售',
        'expected': '预计',
        'grabYourCopyNow': '立即抢购',
        'separatePurchase': '单独购买',
        'startShipping': '开始发货',
        'groupingTogetherForMoreBenefits': '组队拼团，优惠更多',
        'distanceActivity': '距离活动',
        'remainingInventory': '剩余库存',
        'thisItemIsAPreSaleItem': '此商品为预售商品',
        'fullAmountReduction': '满额减',
        'fullPieceDiscount': '满件减',
        'fullDiscount': '满额折',
        'discountOnFullItems': '满件折',
        'collectionOfProducts': '收藏商品',
        'addToCart': '加入购物车',
        'successfullyAddedCart': '成功加入购物车',
        'bookmarked': '已收藏',
        'productNotInStock': '商品无货',
        'productOutOfStock': '商品缺货',
        'productIntroduction': '商品介绍',
        'productReviews': '商品评论',
        'favorableRatingRate': '好评率',
        'goodReview': '好评',
        'mediumRating': '中评',
        'poorReviews': '差评',
        'withPictures': '有图',
        'anonymousUser': '匿名用户',
        'merchantResponse': '商家回复',
        'noComments': '暂无评论',
        'inStoreSearch': '店内搜索',
        'inStoreCategories': '店内分类',
        'hotProducts': '热销好货',
        'congratulationsYouHaveSuccessfullyReceived': '恭喜，您已成功领取',
        'timeOfUse': '使用时间',
        'viewCouponsCollected': '查看已领优惠劵',
        'iWantToOpenAGroup': '我要开团',
        'cellPhoneScanCodeOpenGroup': '手机扫码开团',
        'groupingActivitiesHaveNotStarted': '拼团活动还未开启',
        'searchContentCannotBeEmpty': '搜索内容不能为空',
        'collectionSuccess': '收藏成功',
        'uncollected': '已取消收藏',
        'eventNotStarted': '活动未开始',
        'theEventHasEnded': '活动已结束',
        'insufficientInventory': '库存不足',
        'pleaseEnterTheCorrectNumberOfItems': '请输入正确的商品数量',
        'points': '余额',
        'originalPrice': '原价',
        'recommendGoodies': '推荐好货',
        'marketPrice': '市场价',
        'contactCustomerService': '联系客服',

        'instructions': '说明',
        'longTermValidity': '购买后长期有效',
        'afterPurchase': '购买后',
        'to': '至',
        'effective': '有效',
        'validOnTheSameDay': '购买后当天24点前有效',
        'purchase': '购买后',
        'validDay': '天内有效',
        'refundsAreNotAllowed': '不支持申请退款',
        'notAvailableForPurchase': '活动商品不可购买',
        'gift': '赠品',
        'removeGiftTip1': '移除赠品后退款金额将减去赠品价格￥',
        'removeGiftTip2': '，是否确定移除？',
        'removeGiftTip3': '赠品金额大于或等于主商品当前可退金额，不可移除',
        'removeGiftBtn': '移除'
    },
    'package': {
        'selectPackage': '选择套餐',
        'packageNumber': '套餐数量',
        'packagePrice': '套餐价',
        'selected': '已选择',
        'packageItem': '件套餐商品',
        'discountPackages': '优惠套餐'
    },
    'spike': {
        'spikeTitle': '秒杀商品详情',
        'alreadyFinished': '已抢完',
        'distance': '距离',
        'stillLeft': '还剩',
        'sold': '已售',
        'stillToGoBeforeTheStart': '距离开始还剩',
        'stillToGoBeforeTheEnd': '距离结束还剩',
        'secondsOfActivity': '秒杀活动',
        'currentSecondsAreOver': '当前秒杀活动已结束',
        'theSpikeActivityHasNotStarted': '秒杀活动未开始'
    },

    'discountDetail': {
        'DistanceToEnd': '距结束',
        'SpecialSession': '专场',
        'eventReferencePrice': '活动参考价'
    },

    'cart': {
        'cart': '购物车',
        'selectProductToBuy': '选择购买商品',
        'confirmOrderInformation': '确认订单信息',
        'payToCompleteTheOrder': '支付完成订单',
        'selectAll': '全选',
        'yourShoppingCartIsEmpty': '您的购物车空空如也',
        'goShopping': '去购物',
        'fullReduction': '满减',
        'fullPieceDiscount': '满件减',
        'fullDiscount': '满折',
        'discountOnFullPieces': '满件折',
        'activeItemsAreFullyPurchased': '活动商品已购满',
        'reduced': '已优惠',
        'offers': '优惠',
        'activeItemsPurchasedOver': '活动商品购满',
        'theProductIsAlreadyInTheFavorites': '商品已在收藏夹中',
        'moveToFavorites': '移动到收藏夹',
        'getAFullDiscount': '即可享满减',
        'enjoyAFullDiscount': '即可享满折',
        'goToAdd': '去加购',
        'goToTheList': '去凑单',
        'deleteSelectedProducts': '删除选中商品',
        'selected': '已选',
        'noEventDiscount': '不使用活动优惠',
        'goToSettlement': '结算',
        'totalPrice': '总价',
        'pleaseSelectAtLeastOneItem': '请最少选择一件商品',
        'removeCart': '删除的商品将移出购物车，请您谨慎考虑',
        'deleteThisItem': '确定要删除该商品吗？',
        'pleaseCheckTheItemsWantToDelete': '请勾选要删除的商品~',
        'itemsProducts': '件商品',
        'combo': '套装',
        'giveaways': '【赠品】'
    },

    'coupons': {
        'notUsed': '未使用',
        'alreadyUse': '已使用',
        'expired': '已过期',
        'availableCoupons': '可用优惠券',
        'noCouponsAvailable': '不可用优惠券',
        'reasonForUnavailability': '原因',
        'generalCoupon': '通用神券',
        'sureDeleteTheCoupon': '确定要删除该优惠券吗？',
        'thereAreNoCouponsSoGoGetThem': '这里还没有优惠券，快去领券吧',
        'canUsedPurchaseFullRangeOfProducts': '可用于购买全品类商品',
        'onlySomeProductsOnThePlatformCanPurchased': '仅可购买平台部分商品',
        'validUntil': '有效期至',
        'onlyAfterCouponing': '限领券后',
        'useWithinDays': '天内使用',
        'myCoupons': '我的优惠券',
        'getItNow': '立即领取',
        'recommendedCoupons': '推荐好劵',
        'goToUse': '去使用',
        'useNow': '立即使用',
        'soldOut': '已抢光',
        'robbed': '已抢',
        'sorryNoCouponsAvailable': '很抱歉，没有可领取优惠券',
        'noRelatedCoupons': '暂无相关的优惠券',
        'canUsedToPurchaseTheFullRangeOfProductsOnThePlatform': '可用于购买平台全品类商品',
        'canUsedToPurchaseFullRangeOfProductsFromSelectedStores': '全品类商品可用',
        'canBeUsedToPurchaseSelectedItems': '可用于购买指定商品',
        'limitedPlatform': '限平台',
        'limitedFcategory': '限品类',
        'universalAcrossAllPlatforms': '全平台通用',
        'availableForSelectedProductsAcrossAllPlatforms': '全平台指定商品可用',
        'availableForAllProductsInDesignatedStores': '所有商品可用',
        'someItemsAvailableInSelectedStores': '部分商品可用',
        'deletedCouponsWillNotBeRestored': '已删除的优惠券将不可恢复，请您谨慎考虑',
        'universal': '全场通用(特殊商品除外)',
        'specifiedProductsAvailable': '指定商品可用'
    },

    'userCenter': {

        'orderManagement': '订单管理',
        'myOrders': '我的订单',
        'myCollection': '我的收藏',
        'evaluationList': '评价晒单',
        'customerService': '客户服务',
        'refunds': '退货退款',
        'freeStoreOpening': '免费开店',
        'myStore': '我的店铺',
        'setStoreAccount': '设置店铺账号',
        'changeStorePassword': '修改店铺密码',
        'assetCenter': '资产中心',
        'myPoints': '我的余额',
        'memberCenter': '会员中心',
        'couponCenter': '领券中心',
        'myCoupons': '我的优惠券',
        'accountManagement': '账户管理',
        'accountInformation': '账户信息',
        'receivingAddress': '收货地址',
        'changePassword': '修改密码',
        'storeProgress': '我的店铺审核中，请耐心等待店铺',
        'theAuditNotPass': '审核未通过，请重新发起申请',
        'theStoreOffline': '店铺已下线，请咨询平台'

    },

    'submitOrder': {
        'submitOrder': '提交订单',
        'selectProductToBuy': '选择购买商品',
        'confirmOrderInformation': '确认订单信息',
        'payToCompleteTheOrder': '支付完成订单',
        'payOnline': '在线付款',
        'usePointsForCrediting': '使用余额抵扣',
        'points': '余额',
        'accountTotal': '账户共',
        'pointThisUse': '余额，本次使用',
        'pcsCredit': '个，抵扣',
        'theOrderIsAvailableUpTo': '该订单最多可用',
        'pleaseEnterAMultipleOf10': '请输入10的倍数',
        'amountCredit': '金额抵用',
        'use': '抵用',
        'deliveryMethod': '配送方式',
        'courierDelivery': '快递配送',
        'messageToMerchant': '给商家留言',
        'numberLength100': '选填 (输入长度为100字以内)',
        'numberLength200': '选填，200字以内',
        'platformCoupons': '平台优惠券',
        'totalItems': '件商品，共计',
        'freightPayable': '应付运费',
        'memberPackage': '会员包邮',
        'membershipReductions': '会员减免',
        'promotionalSale': '促销满减',
        'packageOffer': '套餐优惠',
        'memberDiscount': '会员折扣',
        'totalOffer': '优惠总额',
        'pointsCredit': '余额抵扣',
        'totalPayable': '应付总额',
        'sendTo': '寄送至',
        'consignee': '收货人',
        'pleaseAddTheAddressFirst': '请先添加地址',
        'pleaseSelectTheAddressFirst': '请先选择地址',
        'pleaseEnterTheNumberOfPoints': '请输入余额数量',
        'shippingFee': '运费',
        'noteMessage': '备注留言',
        'offerAmount': '优惠金额',
        'inTheMadRushToBuy': '正在疯狂抢购中...',
        'secFailTips': '秒杀活动过于火爆，请稍后重试',
        'seckillReduce': '秒杀优惠',
        'storeDiscount': '店铺优惠',
        'duplicateErrorTips': '请勿重复提交订单',
        'allMessages': '全部留言',
        'pleaseEnter': '请输入',
        'messageCannotBeEmpty': '全部留言中*必填项不能为空'
    },

    'userIndex': {
        'editProfile': '编辑资料',
        'level': '等   级',
        'growthValue': '成长值',
        'privileges': '开通付费会员，即刻尊享7大专属特权！',
        'openMembership': '现在开通付费会员最低',
        'valueOffer': '超值优惠不容错过！',
        'openNow': '马上开通',
        'myOrders': '我的订单',
        'viewAllOrders': '查看全部订单',
        'viewOrders': '查看订单',
        'noRelatedOrders': '暂无相关订单',
        'goShopping': '去逛逛',
        'personalCenter': '个人中心'
    },
    'ucAccount': {
        'accountInformation': '账户信息',
        'memberAvatar': '会员头像',
        'memberGender': '会员性别',
        'man': '男',
        'woman': '女',
        'memberNickname': '会员昵称',
        'maximumCharacters': '最长为15个字符',
        'memberCannotEmpty': '会员昵称不能为空',
        'verifiedMobile': '已验证手机',
        'saveAccount': '保存账户信息',
        'successfullyModified': '修改成功!'
    },
    'ucOrder': {
        'allOrders': '全部订单',
        'generalOrders': '普通订单',
        'groupingOrders': '拼团订单',
        'secondOrders': '秒杀订单',
        'pointsOrders': '余额订单',
        'lastSevenDays': '最近七天',
        'lastThreeMonths': '最近三个月',
        'threeMonthsAgo': '三个月之前',
        'orderNumber': '订单编号',
        'productName': '商品名称',
        'enterNumberpSearch': '请输入订单编号查询',
        'enterProductSearch': '请输入商品名称查询',
        'checkOrder': '查询订单',
        'officialSelf': '官方店',
        'points': '余额',
        'onlinePayment': '在线支付',
        'orderDetails': '订单详情',
        'payNow': '立即付款',
        'remindSellers': '提醒卖家',
        'confirmReceipt': '确认收货',
        'buyAgain': '再次购买',
        'addFavorites': '添加收藏',
        'tips': '提示',
        'sureReceivedGoods': '确定已收到货吗？',
        'considerCarefully': '确认收货后订单状态将发生改变，请您谨慎考虑',
        'deleteTheOrder': '确定删除订单吗？',
        'ordersNotRecovered': '订单删除后将无法恢复，请您谨慎考虑',
        'nanOrderYet': '您还没有订单哦',
        'goShopping': '去逛逛',
        'confirmReceiptSuccessfully': '确认收货成功!',
        'collected': '已收藏',
        'postComment': '发表评论',
        'viewComments': '查看评论',
        'noRelatedOrders': '暂无相关订单',
        'lookOthers': '看看别的',
        'evaluationSunshine': '评价晒单',
        'productCollectionSuccess': '商品收藏成功',
        'physicalGoods': '实物商品',
        'virtualGoods': '虚拟商品'
    },
    'applyReturn': {
        'applyAfterSales': '申请售后',
        'viewAfterSales': '查看售后',
        'refundedProducts': '退款商品',
        'serviceType': '退款方式',
        'refundsOnly': '仅退款',
        'returnsAndRefunds': '退货退款',
        'cargoStatus': '货物状态',
        'noGoodsReceived': '未收到货',
        'receivedGoods': '已收到货',
        'refundReason': '退款原因',
        'select': '请选择',
        'pleaseSelectReason': '请选择退款原因',
        'refundQuantity': '退货数量',
        'refundAmount': '退款金额',
        'refundAmountModified': '本此退款不可修改退款金额',
        'points': '余额',
        'pleaseEnterAmount': '请输入退款金额',
        'maximumRefundable': '最多可退',
        'postage': '邮费',
        'correctRefundAmount': '请填写正确的退款金额',
        'logisticsCannotBeEmpty': '物流单号不能为空',
        'fillLogisticsBillNumber': '请填写物流单号',
        'mobileNumber': '手机号码',
        'mobileNumberTips': '请输入11位手机号码',
        'correctPhoneNumber': '请填写正确的手机号码',
        'refundInstructions': '退款说明',
        'refundInstructionsTips': '请填写退款说明，不超过200字',
        'uploadImages': '上传图片',
        'uploadFiveVouchers': '最多上传五张凭证',
        'SubmitRequest': '提交售后申请',
        'applicationTips1': '申请已提交，请等待商家处理',
        'applicationTips2': '商家同意后，请按照给出的退货地址退货，并记录退货运单号',
        'applicationTips3': '如商家拒绝，您可以修改申请后再次发起，商家会重新处理',
        'applicationTips4': '如商家超时未处理，退货申请将达成，请按系统给出的地址退货',
        'applicationTips5': '撤销申请',
        'applicationTips6-1': '商家已同意申请',
        'applicationTips6-1-1': '请等待商家发放退款',
        'applicationTips6': '商家已同意退货申请，请您尽快退货',
        'applicationTips7': '已填写物流信息，等待商家确认',
        'applicationTips8': '请您在规定时间内填写真实退货物流信息，逾期未填写，退款申请将关闭',
        'applicationTips9': '未与商家协商，请勿使用到付或平邮，以免商家拒签货物',
        'consignee': '收货人',
        'fillLogisticsInformation': '填写物流信息',
        'modifyLogisticsInformation': '修改物流信息',
        'logisticsOrderNoSucced': '请输入正确的物流单号',
        'refundDetails': '退款详情',
        'merchantName': '店铺名称',
        'orderNumber': '订单编号',
        'applicationNumber': '申请件数',
        'refundNumber': '退款编号',
        'refundType': '退款类型',
        'transactionTime': '成交时间',
        'courierPostage': '快递邮费',
        'totalOrderPrice': '订单总价',
        'applicationTips10': '恭喜您，操作成功',
        'applicationTips11': '商家收到货确认无误后，退款将返回您的付款账户',
        'ViewAfterSalesDetails': '查看售后详情',
        'refundReason1': '拍错/多拍/不喜欢',
        'refundReason2': '协商一致退款',
        'refundReason3': '商品破损/少件',
        'refundReason4': '商品与描述不符',
        'refundReason5': '卖家发错货',
        'refundReason6': '质量问题',
        'refundReason7': '其他',
        'refundReason8': '拼团失败',
        'returnedGoods': '退货数量不能小于1',
        'refundReason9': '退货数量不能大于订单商品数量',
        'refundReason10': '本次申请的退款商品是本单最后一件商品，退款金额中包含本单运费，一旦提交申请，本单所有商品的退款申请均不可撤销，是否确认提交',
        'applicationSuccessful': '申请成功！',
        'refundReason11': '是否确定撤销本次退款申请？',
        'refundReason12': '退款申请已撤销',
        'tips': '提示',
        'refundReason13': '已取消删除',
        'logisticsVouchers': '物流凭证',

        'noRelatedRecords': '暂无相关记录',
        'viewDetails': '查看详情',
        'buyerApplication': '买家申请',
        'sellerAcceptance': '卖家接受',
        'buyerShipping': '买家发货',
        'sellerReceipt': '卖家收货',
        'successfulRefund': '退款成功',
        'buyerWithdrawsApplication': '买家撤回申请',
        'merchantRefusal': '商家拒绝',
        'refundClose': '退款关闭',
        'productInformation': '商品信息',
        'returnType': '退货类型',
        'applicationTime': '申请时间',
        'processingStatus': '处理状态',

        'refundList': '退款列表',
        'buyerSubmits': '买家提交申请',
        'sellerProcessing': '卖家处理申请',
        'buyerReturns': '买家退货',
        'refundCompleted': '退款完成',
        'numberOfApplications': '申请数量',
        'total': '总价',
        'refunds': '退款',
        'fillLogistics': '填写物流',
        'modifyLogistics': '修改物流',
        'refundProgress': '退款进度',
        'applicationClosed': '申请已关闭',
        'refundReason14': '买家已撤销退款申请',
        'refundReason15': '卖家拒绝了你的退款申请',
        'reasonsRejection': '拒绝原因',
        'sellerRemarks': '卖家备注',
        'refundReason16': '你可以和卖家积极沟通，重新申请',
        'refundReason17': '卖家已退款，退款成功！',
        'refundReason18': '卖家已收到货，等待卖家处理退款',
        'refundReason19': '买家已寄出货物。',
        'logisticsCompany': '物流公司',
        'shipmentNumber': '物流单号',
        'refundReason20': '卖家已同意退款申请',
        'refundAddress': '退款地址',
        'buyerRequestRefund': '买家申请退款',
        'refundDescription': '退款描述',
        'refundVoucher': '退款凭证',
        'notes': '备注说明',
        'fillInSuccessfully': '填写成功',
        'modifiedSuccessfully': '修改成功',
        'modifyRefundAmount': '修改退款金额',
        'autoReturn': ' 将自动退回'
    },
    'address': {
        'addAddress': '添加地址',
        'modifyAddress': '修改地址',
        'addRecipientInformation': '新增收件人信息',
        'recipient': '收件人',
        'recipientNameCannotBeEmpty': '收件人名称不能为空',
        'deleteShippingAddress': '确定要删除该收货地址吗？',
        'deleteShippingTips': '已删除的地址将不可恢复，请您谨慎考虑',
        'createdTips1': '您已创建',
        'createdTips2': '个收货地址，最多可创建',
        'createdTips3': '个',
        'default': '默认',
        'setDefault': '设为默认',
        'notAddAdress': '您还没有添加地址哦',
        'receivingAddress': '收货地址',
        'createdTips4': '新增收货地址已达到上限',
        'createdTips5': '新增收货地址',
        'cellPhoneNumberCannotBeEmpty': '手机号码不能为空',
        'cellPhoneNumberRightFormat': '手机号码格式不对',
        'pleaseYourCompleteRegionalInformation': '请您填写完整地地区信息',
        'saveRecipientInformation': '保存收件人信息'
    },
    'collection': {
        'productCollection': '商品收藏',
        'storeCollection': '店铺收藏',
        'allProducts': '全部商品',
        'failedProducts': '失效商品',
        'batchManagement': '批量管理',
        'completeManagement': '完成管理',
        'selectAll': '全选',
        'cancelCollection': '取消收藏',
        'enterProductName': '请输入商品名称',
        'enterStoreuctName': '请输入店铺名称',
        'Search': '搜索',
        'goToBuy': '去购买',
        'enterStore': '进入店铺',
        'noCollection': '暂无收藏',
        'lookOther': '看看别的',
        'unbookmark1': '确认要取消收藏该',
        'unbookmark2': '取消收藏之后',
        'unbookmark3': '将不容易被找到哦',
        'product': '商品',
        'store': '店铺',
        'myCollection': '我的收藏',
        'notPurchase': '该商品已不能购买',
        'storeReviewTips1': '店铺审核中',
        'storeReviewTips2': '店铺状态异常',
        'storeReviewTips3': '店铺已下线',
        'storeReviewTips4': '商家尚未营业',
        'storeReviewTips5': '商家已暂停营业'
    },
    'freeShop': {
        'freeShop': '免费开店',
        'storeName': '店铺名称',
        'storeHome': '店铺首页',
        'storeNameTips1': '请输入店铺名称 字数不超过20字',
        'storeNameTips2': '店铺名称不能为空,且不能有特殊字符',
        'contactNumber': '联系电话',
        'contactNumberTips1': '请输入联系电话号码',
        'contactNumberTips2': '请输入正确格式的联系电话',
        'area': '所在地区',
        'pleaseSelect': '请选择',
        'selectArea': '请选择所在地区',
        'detailedAddress': '详细地址',
        'detailedAddressTips': '详细地址不能少于5个字',
        'locationMap': '定位地图',
        'storeLocation': '店铺所在位置',
        'storeDescription': '店铺描述',
        'storeDescriptionTips1': '请填写店铺描述，10-200字',
        'storeDescriptionTips2': '店铺描述不能为空（范围：10-200字）',
        'uploadInformation': '上传资料',
        'storeLogo': '店铺logo',
        'businessLicense': '营业执照',
        'frontCard': '身份证正面',
        'reverseCard': '身份证反面',
        'uploadInformationTips1': '请上传店铺logo',
        'uploadInformationTips2': '请上传营业执照',
        'uploadInformationTips3': '请上传身份证正面',
        'uploadInformationTips4': '请上传身份证反面',
        'submitApplication': '提交开店申请',
        'submitApplicationTips': '您的开店申请审核未通过，是否修改信息并重新提交申请？',
        'modifyInformation': '修改信息',
        'none': '无',
        'auditRemarks': '审核备注',
        'cancel': '取 消',
        'ok': '确 定',
        'applicationTips': '您的开店申请正在加急审核中，请耐心等候...',
        'applicationSuccessfully': '店铺申请提交成功！',
        'selectProvince': '请先选择省市区'
    },
    'myPoints': {
        'pointBreakdown': '余额明细',
        'redeemingGoods': '兑换商品',
        'commonProblems': '常见问题',
        'currentPoints': '当前余额',
        'expiredPoints': '已过期余额',
        'pointsTips1': '（余额自获得日起第',
        'pointsTips2': '年内将会过期，请您及时使用。）',
        'redeem': '兑换更多超值商品',
        'sourceUse': '来源/用途',
        'pointsChange': '余额变化',
        'Time': '时间',
        'sourceUseTips1': '注册获赠余额',
        'sourceUseTips2': '退款扣除购物获赠余额',
        'sourceUseTips3': '购买商品获赠余额',
        'orderNumber': '订单编号',
        'sourceUseTips4': '等级提升获赠余额',
        'sourceUseTips5': '签到获赠余额',
        'sourceUseTips6': '购买商品抵扣余额',
        'sourceUseTips7': '商品退款退回抵扣余额',
        'sourceUseTips8': '余额过期',
        'sourceUseTips9': '充值储蓄获赠余额',
        'sourceUseTips10': '系统变更',
        'points': '余额',
        'marketPrice': '市场价',
        'pointsFAQ': '余额常见问题',
        'myPoints': '我的余额'
    },
    'ucPassword': {
        'changePassword': '修改密码',
        'verifiedMobile': '已验证手机',
        'mobileCode': '手机验证码',
        'enterCode': '请输入验证码',
        'getCode': '获取验证码',
        'pleaseEnter': '请输入验证码',
        'sendCodeTips1': '校验码已发出，请注意查收短信，可以在',
        'sendCodeTips2': '秒后要求系统重新发送',
        'completed': '已完成，下一步',
        'enterNewPassword': '输入新密码',
        'enterNewPasswordTips1': '请输入密码',
        'enterNewPasswordTips2': '请输入正确格式的密码',
        'enterNewPasswordTips3': '密码由字母加数字或符号至少两种以上字符组成6-20位半角字符，区分大小写',
        'enterNewPasswordTips4': '重复新密码',
        'enterNewPasswordTips5': '两次密码不一致',
        'congratulations': '恭喜您，操作成功',
        'jumping': '网页正在跳转，3秒后跳转到账户登录页面',
        'jumpNow': '立即跳转',
        'storeAccountSettings': '店铺账号设置',
        'loginAccount': '登录账号',
        'enterNumber': '请输入登录手机号',
        'enterNumberTips': '请输入正确的登录手机号码',
        'verificationCode': '验证码',
        'CaptchaEmpty': '验证码不能为空',
        'setPassword': '设置密码',
        'setPasswordTips': '请设置6-12位登录密码',
        'confirmPassword': '确认密码',
        'enterLoginAgain': '请再次输入登录密码',
        'notEnteredPassword': '确认密码与输入密码不一致',
        'originalPassword': '原 密 码',
        'enterOriginalPassword': '请输入原登录密码',
        'originalNotEmpty': '原登录密码不能为空',
        'newPassword': '新 密 码',
        'PleaseEnterNewPassword': '请输入新登录密码',
        'enterNewAgain': '请再次输入新登录密码',
        'newNotMatch': '确认密码与新登录密码不一致',
        'saveAccountInformation': '保存账户信息',
        'passwordTips': '请用上面的账号密码在PC端打开店铺管理中心进行登录。商家管理端地址',
        'AccountSuccessfully': '店铺账号设置成功！',
        'PasswordSuccessfully': '店铺密码修改成功！',
        'thinkingPassword': '想起密码？',
        'goLogin': '去登录',
        'verifyMobileNumber': '验证手机号',
        'fillInPassword': '填写密码',
        'completion': '完成',
        'enterCellNumber': '请输入手机号',
        'enterCorrectNumber': '请输入正确的手机号',
        'setYourPassword': '请您设置密码',
        'enterPasswordAgain': '再次输入密码',
        'nextStep': '下一步',
        'passwordChangeSuccessful': '恭喜您，密码修改成功！',
        'jumpPage': 's后自动跳转到登录页',
        'mallHome': '商城首页',
        'officialWebsite': '官方网站',
        'openSourceWebsite': '开源官网',
        'forgotPassword': '忘记密码'
    },
    'memberCenter': {
        'hiDear': 'Hi，尊敬的',
        'purchaseMembership': '购买会员',
        'membershipRenewal': '会员续费',
        'growthValue': '成长值',
        'memberFull': '会员等级已满',
        'upgradeable': '可升级',
        'pointsMall': '余额商城',
        'memberBenefits': '会员权益',
        'moreBenefits': '更多权益',
        'productShipping': '商品包邮',
        'goodsFree': '购买自营商品包邮',
        'exclusiveCoupons': '专属好券',
        'exclusiveOffers': '会员领取专属优惠',
        'shoppingPoints': '购物余额',
        'membershipMore': '会员等级越高越多',
        'memberDiscount': '会员折扣',
        'membersEnjoy': '会员享受打折优惠',
        'dailySign': '每日签到',
        'pointsTask': '余额任务',
        'signIn': '签到',
        'signed': '已签',
        'shoppingMall': '商城购物',
        'purchase1': '商城购物满',
        'purchase2': '元可获得1余额',
        'goShopping': '去购物',
        'registerNewUser': '注册新用户',
        'newPoints1': '新用户注册可获赠',
        'newPoints2': '余额',
        'completed': '已完成',
        'exchangeGoods': '兑换商品',
        'viewMore': '查看更多',
        'points': '余额',
        'marketPrice': '市场价',
        'signedTips1': '今天已经签到过啦~',
        'signedTips2': '这天已经签到过啦~',
        'signedTips3': '时间未到,还不能签到!',
        'currentGrowthValue': '当前成长值',
        'expirationDate': '有效期',
        'memberGrowthValue': '会员成长值',
        'availablePurchase': '可购买',
        'openingTips1': '您正在开通',
        'openingTips2': '套餐，请支付',
        'yuan': '元',
        'weChatPayment': '微信支付',
        'weChatSweepPayment': '微信扫一扫支付',
        'completedPayment': '已完成支付',
        'cancel': '取 消',
        'purchaseSuccess': '购买成功',
        'purchaseFailure': '购买失败',
        'viewPointsDetails': '查看余额明细',
        'memberCenter': '会员中心',
        'home': '首页',
        'expiration': '到期',
        'logout': '退出'
    },
    'orderDetails': {
        'orderDetails': '订单详情',
        'personalCenter': '个人中心',
        'orderNumber': '订单号',
        'remainingTime': '剩余时间',
        'payNow': '立即支付',
        'cancellation': '取消订单',
        'informationTips': '付款时请您仔细查看订单细节以及配送信息，以确保您能及时收到货物。',
        'submitOrder': '提交订单',
        'buyerPayment': '买家付款',
        'goodsOut': '商品出库',
        'waitingForReceipt': '等待收货',
        'orderCompletion': '订单完成',
        'packages': '包裹',
        'shipmenttime': '发货时间',
        'courierCompany': '快递公司',
        'waybillNumber': '运单号',
        'logisticsStatus': '物流状态',
        'noRecords': '没有记录',
        'received': '已揽收',
        'inTransit': '运输途中',
        'signed': '已签收',
        'reachCity': '达到目的城市',
        'faultyPackages': '问题件',
        'logisticsTips1': '暂无物流信息，请您稍后再试',
        'logisticsTips2': '商家已发货，请等待快递拣货',
        'logisticsTips3': '您已支付完成，请等待商家发货',
        'logisticsTips4': '您提交了订单，请等待系统确认',
        'receiptInformation': '收货信息',
        'selfPickupInformation': '自提信息',
        'receiver': '收件人',
        'picker': '提货人',
        'contactInformation': '联系方式',
        'receiptAddress': '收货地址',
        'pickupAddress': '自提地址',
        'deliveryInformation': '配送信息',
        'deliveryMethod': '配送方式',
        'generalDelivery': '普通快递',
        'buyerPickup': '买家自提',
        'noExpressDelivery': '无需快递',
        'sameCityDelivery': '同城配送',
        'none': '无',
        'shippingFee': '运 费',
        'paymentInformation': '付款信息',
        'paymentMethod': '付款方式',
        'onlinePayment': '在线支付',
        'paymentTime': '付款时间',
        'totalMerchandise': '商品总额',
        'offerAmount': '优惠金额',
        'amountPayable': '应付金额：',
        'actualAmount': '实付金额：',
        'points': '余额',
        'orderRemarks': '订单备注',
        'requestRefund': '申请退款',
        'viewRefund': '查看退款',
        'evaluation': '评价',
        'repurchase': '再次购买',
        'refundsEntireOrders': '整单退款',
        'productOffer': '商品优惠',
        'confirmCancellation': '确认取消订单吗？',
        'confirmCancellationTips': '订单取消后将无法恢复，请您谨慎考虑',
        'orderChanged': '订单状态已改变',
        'orderCancelled': '订单已取消',
        'productShipping': '商品运费',
        'shippingDiscount': '运费减免',
        'storeCoupons': '店铺优惠券',
        'merchantsModifyPrices': '商家改价',
        'toBeUsed': '待使用',
        'sheets': '张',
        'used': '已使用',
        'refunded': '已退款',
        'voucherCode': '券码'
    },
    'shopInfo': {
        'selfShop': '自营店铺',
        'preferredStores': '优选好店',
        'generalStore': '普通店铺',
        'followed': '已关注',
        'follow': '关注',
        'noAnnouncement': '暂无公告',
        'homePage': '首页',
        'allCategories': '全部分类',
        'noData': '暂无数据',
        'search': '搜索',
        'hotProducts': '热卖商品',
        'mallHome': '商城首页',
        'storeHome': '店铺主页',
        'storeList': '店铺列表',
        'noRelevant': '抱歉，没有找到相关店铺'
    },
    'comments': {
        'personalCenter': '个人中心',
        'postComment': '发表评论',
        'commentDetails': '评论详情',
        'specification': '规格',
        'orderNumber': '订单编号',
        'transactionTime': '成交时间',
        'productRating': '商品评分',
        'specificDescription': '具体描述',
        'enterUpTo150Characters': '最多输入150字',
        'uploadImages': '上传图片',
        'uploadUpTo9Photos': '最多上传9张',
        'commentsOnPictures': '评论图片',
        'none': '无',
        'anonymousOrNot': '是否匿名',
        'backToList': '返回列表',
        'evaluationCannotBeEmpty': '评价不能为空',
        'submitCommentSuccessfully': '提交评论成功',
        'provideYourComments': '提供您的意见，我们会做的更好'
    },
    'payment': {
        'orderSubmittedSuccessfully': '订单提交成功，请尽快付款',
        'actualPayment': '实付',
        'orderHasExpired': '订单已过期，请重新下单',
        'chooseAnotherPaymentMethod': '选择其他支付方式',
        'qrCodeHasExpired': '二维码已过期，请重新下单',
        'weChatSweepPayment': '微信扫一扫支付',
        'orderPayment': '订单支付',
        'selectProductToBuy': '选择购买商品',
        'confirmOrderInformation': '确认订单信息',
        'payToCompleteTheOrder': '支付完成订单',
        'totalPayable': '应付总额',
        'receiptInformation': '收货信息',
        'productName': '商品名称',
        'selectPaymentMethod': '选择支付方式',
        'payNow': '立即付款',
        'points': '余额',
        'orderDetails': '订单详情',
        'aliPay': '支付宝',
        'wechatPay': '微信支付',
        'pointPay': '余额支付',
        'balancePay': '储蓄支付'
    },
    'chat': {
        'userName': '用户名',
        'official': '官方',
        'stores': '店铺',
        'clickToLoadMore': '点击加载更多',
        'mallCustomerService': '商城客服',
        'sendLink': '发送链接',
        'send': '发送',
        'inquiring': '正在查询',
        'myOrders': '我的订单',
        'recentlyViewed': '最近浏览',
        'orderNumber': '订单号',
        'none': '无',
        'loading': '加载中',
        'noMore': '没有更多了',
        'read': '已读',
        'onLine': '(在线)',
        'offLine': '(离线)',
        'unRead': '未读',
        'noRecord': '暂无记录',
        'customerOffline': '当前客服不在线',
        'loginOtherSide': '用户在别处登陆,请刷新页面',
        'reLogin': '无法获取用户信息,请重新登陆',
        'sendOrder': '发送订单',
        'pleaseLoginAgain': '账号已下线，请重新登录',
        'paymentAmount': '支付金额',
        'onlineCustomerService': '在线客服',
        'productLinks': '商品链接',
        'sorryYouHaveBeenDisconnected': '对不起您已断开连接，正在重连',
        'reConsultation': '您已在其他页面发起咨询，当前咨询已失效，请点击重新咨询',
        'reconsultationBtn': '重新咨询',
        'close': '关闭',
        'connetBroken': '连接已断开，请刷新页面重试',
        'tips': '提示',
        'confirm': '确认',
        'cancel': '取消'
    },
    'invoice': {
        'InvoiceDetails': '发票详情',
        'orderInfo': '订单信息',
        'orderStatus': '订单状态',
        'orderNumber': '订单编号',
        'orderCreateTime': '下单时间',
        'invoiceType': '发票类型',
        'generalInvoice': '普通电子发票',
        'noInvoice': '本次不开具发票',
        'invoiceInfo': '发票信息',
        'invoiceStatus': '发票状态',
        'invoiced': '已开票',
        'application': '申请中',
        'invoiceContent': '发票内容',
        'productDetails': '商品明细',
        'invoiceTitle': '发票抬头',
        'companyTaxNumber': '公司税号',
        'invoiceDownload': '发票下载',
        'uploadTime': '开票时间',
        'invoiceExchange': '发票换开',
        'viewInvoice': '查看发票',
        'updateInvoice': '修改发票',
        'personal': '个人',
        'unit': '单位',
        'personalName': '个人名称',
        'inputTips1': '请输入“个人”或个人姓名',
        'unitName': '单位名称',
        'inputTips2': '请输入单位名称',
        'inputTips3': '请输入纳税人识别号',
        'TaxpayerIDNumber': '纳税人识别号',
        'invoiceInstructions': '发票须知',
        'invoiceTips1': '依照税局最新开票法规，发票开具内容均为明细;',
        'invoiceTips2': '电子普通发票和纸质普通发票具备同等法律效力，可支持报销入账;',
        'invoiceTips3': '开票金额为实际支付金额，不包括优惠券、余额、红包等支付金额;',
        'invoiceTips4': '电子发票可在确认收货后，在“发票详情”页面下载。',
        'saveInvoiceInformation': '保存发票信息',
        'viewInvoiceDetail': '查看发票详情',
        'InvoicingRequest': '申请开票',
        'update': '修改',
        'successTip': '申请成功',
        'successTip1': '更新成功',
        'questions': '常见问题',
        'questions1': '发票类型说明：默认开具电子普通发票，电子普通发票和纸质普通发票具备同等法律效力，可支持报销入账和售后维权，如需开具增值税专用发票，请与商家联系；',
        'questions2': '发票开具时间：订单确认收货后，将由商家开具，可在“个人中心 - 我的订单 - 查看发票”页面查看进度；',
        'questions3': '发票开具金额：开票金额为您实际支付金额，不包括优惠券、余额、红包等支付金额；',
        'questions4': '发票开具内容：依照税局最新开票法规，发票内容均为商品明细，将显示详细商品名称与价格信息；',
        'questions5': '发票哪里下载：订单完成后，可在“个人中心 - 我的订单 - 查看发票”页面下载发票。'
    },
    'history': {
        'history': '我的足迹',
        'historyEmpty': '您还没有浏览过商品',
        'deleteHistory': '删除足迹',
        'deleteHistoryTips': '确定要删除足迹吗'
    },
    'notice': {
        'noticeGone': '公告不存在'
    }
}
export default zh
