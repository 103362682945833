const Layout = () => import('@/layout/index.vue')

export default {
    path: '/hcCulture',
    component: Layout,
    redirect: '/hcCulture/cultureSquare',
    name: 'hcCulture',
    meta: {
        title: '嗨圈文化',
        icon: 'sidebar-care-type'
    },
    children: [
        {
            path: 'cultureSquare',
            name: 'cultureSquare',
            component: () => import('@/views/hcCulture/cultureSquare/index.vue'),
            meta: {
                title: '文化广场'
            },
            children: [
                {
                    path: 'detail',
                    name: 'cltureDetail',
                    component: () => import('@/views/hcCulture/cultureSquare/detail.vue'),
                    meta: {
                        title: '内容详情',
                        sidebar: false,
                        copyright: false,
                        activeMenu: '/hcCulture/cultureSquare'
                    }
                }
            ]
        }]
}
