import path from 'path-browserify'
import useSettingsStore from '@/store/modules/settings'
import useUserStore from '@/store/modules/user'
import storage from '@/util/storage'
import { isURL } from './validator.js'
import { MB } from '@/util/memberApi'
import api from '@/api'

export function deepClone(target) {
    // 定义一个变量
    let result
    // 如果当前需要深拷贝的是一个对象的话
    if (typeof target === 'object') {
        // 如果是一个数组的话
        if (Array.isArray(target)) {
            result = [] // 将result赋值为一个数组，并且执行遍历
            for (let i in target) {
                // 递归克隆数组中的每一项
                result.push(deepClone(target[i]))
            }
            // 判断如果当前的值是null的话；直接赋值为null
        } else if (target === null) {
            result = null
            // 判断如果当前的值是一个RegExp对象的话，直接赋值
        } else if (target.constructor === RegExp) {
            result = target
        } else {
            // 否则是普通对象，直接for in循环，递归赋值对象的所有值
            result = {}
            for (let i in target) {
                result[i] = deepClone(target[i])
            }
        }
        // 如果不是对象的话，就是基本数据类型，那么直接赋值
    } else {
        result = target
    }
    // 返回最终结果
    return result
}

function hasPermission(permission) {
    const settingsStore = useSettingsStore()
    const userStore = useUserStore()
    if (settingsStore.app.enablePermission) {
        return userStore.permissions.some(v => {
            return v === permission
        })
    } else {
        return true
    }
}
export function shareClick(id, fn) {
    const userStore = useUserStore()
    MB.pickConversation_contact({
        corpId: userStore.corpId, // 企业id
        isConfirm: false // 是否弹出确认窗口，默认为true
    })
        .then(async({ result, type }) => {
            if (type === 'SAAS_HR') {
                api.post('/p/share/dingShare', {
                    cid: result.cid,
                    materialId: id
                })
                    .then(res => {
                        if (fn) {
                            fn()
                        }
                        ElMessage({
                            message: res.msg,
                            type: 'success'
                        })

                    })
            }
        })
        .catch(err => {
            console.log(err)
        })

}

export function auth(value) {
    let auth
    if (typeof value === 'string') {
        auth = hasPermission(value)
    } else {
        auth = value.some(item => {
            return hasPermission(item)
        })
    }
    return auth
}

export function authAll(value) {
    const auth = value.every(item => {
        return hasPermission(item)
    })
    return auth
}

export function isExternalLink(path) {
    return /^(https?:|mailto:|tel:)/.test(path)
}

export function resolveRoutePath(basePath, routePath) {
    if (isExternalLink(routePath)) {
        return routePath
    }
    if (isExternalLink(basePath)) {
        return basePath
    }
    return basePath ? path.resolve(basePath, routePath) : routePath
}

export function getImgPreviewPath(url) {
    if (url.includes('http://limahai-outing')) {
        return url
    }
    return `${import.meta.env.VITE_APP_API_BASEURL }common/read?fileName=${url}`
}

export function inputErrorScroll() {
    setTimeout(() => {
        const errorDiv = document.getElementsByClassName('is-error')
        errorDiv[0].scrollIntoView({
        // 滚动到指定节点
            block: 'center',
            behavior: 'smooth'
        })
    }, 0)
}
// 扁平数据结构转Tree
export function arrayToTree(items) {
    const result = []   // 存放结果集
    const itemMap = {}  //
    for (const item of items) {
        const id = item.id
        const pid = item.pid

        if (!itemMap[id]) {
            itemMap[id] = {
                children: []
            }
        }

        itemMap[id] = {
            ...item,
            children: itemMap[id]['children']
        }

        const treeItem =  itemMap[id]

        if (pid === 0) {
            result.push(treeItem)
        } else {
            if (!itemMap[pid]) {
                itemMap[pid] = {
                    children: []
                }
            }
            itemMap[pid].children.push(treeItem)
        }

    }
    return result
}

export function isClient(platform) {
    let result = ''
    if (navigator.userAgent.indexOf('DingTalk') !== -1) {
        result = 'DingTalk'
    }
    const ua = window.navigator.userAgent.toLowerCase()
    if ((ua.match(/micromessenger/i) == 'micromessenger') && (ua.match(/wxwork/i) == 'wxwork')) {
        result = 'wxwork'
    }
    return result === platform
}
export function getPlatform() {
    return storage.session.get('platform') || 'SAAS_HR'
}
export function betweenTimestamp(startTime, endTime) {
    let newTime = new Date().getTime()
    let betweenTime = 0
    // 还没开始 相差时间为负数
    if (newTime < startTime) {
        betweenTime = newTime - startTime
    } else {
        // 还没结束
        betweenTime = endTime - newTime
    }
    return betweenTime
}
export function dateToTimestamp(dateStr) {
    if (!dateStr) {
        return ''
    }
    let newDataStr = dateStr.replace(/\.|\-/g, '/')
    let date = new Date(newDataStr)
    let timestamp = date.getTime()
    return timestamp
}
/**
* 根据相隔时间戳，计算相隔时间
*/
export function betweenTime(betweenTimestamp) {
    // 正的时间还是负的时间
    let signs = 1
    if (betweenTimestamp < 0) {
        betweenTimestamp = -betweenTimestamp
        signs = 0
    }

    if (betweenTimestamp > 1000) {
        let time = betweenTimestamp / 1000
        // 获取天、时、分、秒
        let day = parseInt(time / (60 * 60 * 24))
        let hou = parseInt(time % (60 * 60 * 24) / 3600)
        let min = parseInt(time % (60 * 60 * 24) % 3600 / 60)
        let sec = parseInt(time % (60 * 60 * 24) % 3600 % 60)
        return {
            day: `${timeFormat(day)}`,
            hou: `${timeFormat(hou)}`,
            min: `${timeFormat(min)}`,
            sec: `${timeFormat(sec)}`,
            signs: signs
        }
    }
    return {
        day: '00',
        hou: '00',
        min: '00',
        sec: '00',
        signs: signs
    }
}
/**
* 小于10的格式化函数
*/
export function timeFormat(times) {
    return times < 10 ? '0' + times : times
}
/**
 * 处理图片地址(完整url正常显示，非完整url拼接域名)
 * @param url
 * @returns {*|string}
 */
export function formatImageUrl(url) {
    const resourcesUrl = import.meta.env.VITE_APP_API_PICDOMAIN
    return isURL(url) ? url : resourcesUrl + url
}
