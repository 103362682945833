import zh from '@/locales/index'
const getTxt = function(str) {
    let t = str.split('.')
    var txt = null
    for (let i in t) {
        if (txt == null) {
            txt = zh[t[i]]
        } else {
            txt = txt[t[i]]
        }
    }
    return txt
}
export default getTxt
