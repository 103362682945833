const Layout = () => import('@/layout/index.vue')
export default {
    path: '/commend',
    component: Layout,
    redirect: '/commend/corporationCommend',
    name: 'commend',
    meta: {
        title: '特色表彰',
        icon: 'sidebarrecordtype'
    },
    children: [
        {
            path: 'corporationCommend',
            name: 'corporationCommendList',
            component: () => import('@/views/commend/corporationCommend/index.vue'),
            meta: {
                title: '公司表彰'
            },
            children: [
                {
                    path: 'add',
                    name: 'corporationCommendAdd',
                    component: () => import('@/views/commend/corporationCommend/commendEdit.vue'),
                    meta: {
                        title: '新增表彰',
                        icon: 'sidebar-care-types01',
                        sidebar: false,
                        copyright: false,
                        activeMenu: '/commend/corporationCommend'
                    }
                },
                {
                    path: 'edit',
                    name: 'corporationCommendEdit',
                    component: () => import('@/views/commend/corporationCommend/commendEdit.vue'),
                    meta: {
                        title: '表彰编辑',
                        icon: 'sidebar-care-types01',
                        sidebar: false,
                        copyright: false,
                        activeMenu: '/commend/corporationCommend'
                    }
                },
                {
                    path: 'toCommend',
                    name: 'corporationCommendToCommend',
                    component: () => import('@/views/commend/corporationCommend/toCommend.vue'),
                    meta: {
                        title: '去表彰',
                        icon: 'sidebar-care-types01',
                        sidebar: false,
                        copyright: false,
                        activeMenu: '/commend/corporationCommend'
                    }
                }

            ]
        },
        {
            path: 'exampleList',
            name: 'exampleList',
            component: () => import('@/views/commend/corporationCommend/index.vue'),
            meta: {
                title: '学习榜样'
            },
            children: [
                {
                    path: 'add',
                    name: 'exampleListAdd',
                    component: () => import('@/views/commend/corporationCommend/commendEdit.vue'),
                    meta: {
                        title: '新增榜样',
                        icon: 'sidebar-care-types01',
                        sidebar: false,
                        copyright: false,
                        activeMenu: '/commend/exampleList'
                    }
                },
                {
                    path: 'edit',
                    name: 'exampleListEdit',
                    component: () => import('@/views/commend/corporationCommend/commendEdit.vue'),
                    meta: {
                        title: '榜样编辑',
                        icon: 'sidebar-care-types01',
                        sidebar: false,
                        copyright: false,
                        activeMenu: '/commend/exampleList'
                    }
                },
                {
                    path: 'toCommend',
                    name: 'toCommend',
                    component: () => import('@/views/commend/corporationCommend/toCommend.vue'),
                    meta: {
                        title: '去表扬',
                        icon: 'sidebar-care-types01',
                        sidebar: false,
                        copyright: false,
                        activeMenu: '/commend/exampleList'
                    }
                }
            ]
        },
        {
            path: 'record/list',
            name: 'myRecordList',
            component: () => import('@/views/commend/myRecord/index.vue'),
            meta: {
                title: '我的记录'
            }
        }
    ]
}
