import { deepClone, isExternalLink } from '@/util'
import api from '@/api'
import { systemRoutes, asyncRoutes } from '@/router/routes'

import useSettingsStore from './settings'
import useUserStore from './user'

function hasPermission(permissions, route) {
    let isAuth = false
    if (route.meta && route.meta.auth) {
        isAuth = permissions.some(auth => {
            if (typeof route.meta.auth == 'string') {
                return route.meta.auth === auth
            } else {
                return route.meta.auth.some(routeAuth => {
                    return routeAuth === auth
                })
            }
        })
    } else {
        isAuth = true
    }
    return isAuth
}

function filterAsyncRoutes(routes, permissions) {
    const res = []
    routes.forEach(route => {
        let tmpRoute = deepClone(route)
        if (hasPermission(permissions, tmpRoute)) {
            if (tmpRoute.children) {
                tmpRoute.children = filterAsyncRoutes(tmpRoute.children, permissions)
                tmpRoute.children.length && res.push(tmpRoute)
            } else {
                res.push(tmpRoute)
            }
        }
    })
    return res
}

function formatBackRoutes(routes, views = import.meta.glob('../../views/**/*.vue')) {
    return routes.map(route => {
        switch (route.component) {
            case 'Layout':
                route.component = () => import('@/layout/index.vue')
                break
            default:
                if (route.component) {
                    route.component = views[`../../views/${route.component}`]
                }
        }
        if (route.children) {
            route.children = formatBackRoutes(route.children, views)
        }
        return route
    })
}
function routersFilter(newAuthority) { // 后端获取的路由
    const res = []
    // 本地路由
    let newAsyncRoutes = asyncRoutes[0].children
    newAsyncRoutes.forEach(item => {
        if (item.children) {
            item.children.forEach(items => {
                res.push(items)
            })
            res.push(item)
        }
    })
    console.log(newAuthority)
    console.log(res)
    newAuthority.forEach(router => { // 传过来的路/后端拿到的
        let children = router.children
        console.log('childrenchildrenchildrenchildren', children)
        if (children.length) {
            res.forEach(items => {
                // 处理一级菜单
                let url = children[0].url
                if (url.slice(0, 1) === '/') {
                    url = url.slice(0, 1)
                }
                let urlArr = url.split('/')
                if (items.path === ('/' + urlArr[0])) {
                    items.meta.icon = router.icon
                    items.meta.title = router.resourceName
                    items.meta.auth = [router.model]
                    router.component = items.component
                    router.meta = items.meta
                    router.name = items.name
                    router.path = items.path
                    router.redirect = items.redirect
                }
                // 处理二级菜单
                children.forEach(item => {
                    let newUrl = item.url
                    if (newUrl.slice(0, 1) === '/') {
                        newUrl = url.slice(0, 1)
                    }
                    let newUrlArr = newUrl.split('/')
                    let newUrlArr1 = items.path.split('/')
                    if (newUrlArr[1] === newUrlArr1[0]) {
                        // console.log(items)
                        items.meta.icon = item.icon
                        items.meta.title = item.resourceName
                        items.meta.auth = [item.model]
                        item.children = items.children
                        item.component = items.component
                        item.meta = items.meta
                        item.name = items.name
                        item.path = items.path
                        item.redirect = items.redirect
                    }
                })
            })
        }
    })
    return newAuthority

}
function compare(obj1, obj2) {
    var val1 = obj1.orderNum
    var val2 = obj2.orderNum
    if (val1 < val2) {
        return -1
    } else if (val1 > val2) {
        return 1
    } else {
        return 0
    }
}

// 将多层嵌套路由处理成平级
function flatAsyncRoutes(routes, breadcrumb = [], baseUrl = '') {
    let res = []
    routes.forEach(route => {
        if (route.children) {
            let childrenBaseUrl = ''
            if (baseUrl == '') {
                childrenBaseUrl = route.path
            } else if (route.path != '') {
                childrenBaseUrl = `${baseUrl}/${route.path}`
            }
            let childrenBreadcrumb = deepClone(breadcrumb)
            if (route.meta.breadcrumb !== false) {
                childrenBreadcrumb.push({
                    path: childrenBaseUrl,
                    title: route.meta.title,
                    hide: !route.meta.breadcrumb && route.meta.breadcrumb === false
                })
            }
            let tmpRoute = deepClone(route)
            tmpRoute.path = childrenBaseUrl
            tmpRoute.meta.breadcrumbNeste = childrenBreadcrumb
            delete tmpRoute.children
            res.push(tmpRoute)
            let childrenRoutes = flatAsyncRoutes(route.children, childrenBreadcrumb, childrenBaseUrl)
            childrenRoutes.map(item => {
                // 如果 path 一样则覆盖，因为子路由的 path 可能设置为空，导致和父路由一样，直接注册会提示路由重复
                if (res.some(v => v.path == item.path)) {
                    res.forEach((v, i) => {
                        if (v.path == item.path) {
                            res[i] = item
                        }
                    })
                } else {
                    res.push(item)
                }
            })
        } else {
            let tmpRoute = deepClone(route)
            if (baseUrl != '' && !isExternalLink(tmpRoute.path)) {
                if (tmpRoute.path != '') {
                    tmpRoute.path = `${baseUrl}/${tmpRoute.path}`
                } else {
                    tmpRoute.path = baseUrl
                }
            }
            // 处理面包屑导航
            let tmpBreadcrumb = deepClone(breadcrumb)
            tmpBreadcrumb.push({
                path: tmpRoute.path,
                title: tmpRoute.meta.title,
                hide: !tmpRoute.meta.breadcrumb && tmpRoute.meta.breadcrumb === false
            })
            tmpRoute.meta.breadcrumbNeste = tmpBreadcrumb
            res.push(tmpRoute)
        }
    })
    return res
}

const useRouteStore = defineStore(
    // 唯一ID
    'route',
    {
        state: () => ({
            isGenerate: false,
            routes: [],
            currentRemoveRoutes: []
        }),
        getters: {
            // 扁平化路由（将三级及以上路由数据拍平成二级）
            flatRoutes: state => {
                const settingsStore = useSettingsStore()
                let routes = []
                if (state.routes) {
                    if (settingsStore.app.routeBaseOn !== 'filesystem') {
                        state.routes.map(item => {
                            routes.push(...deepClone(item.children))
                        })
                        routes.map(item => {
                            if (item.children) {
                                item.children = flatAsyncRoutes(item.children, [], item.path)
                            }
                        })
                    } else {
                        state.routes.map(item => {
                            routes.push(deepClone(item))
                        })
                    }
                }
                return routes
            },
            flatSystemRoutes: () => {
                let routes = []
                systemRoutes.map(item => {
                    routes.push(deepClone(item))
                })
                routes.map(item => {
                    if (item.children) {
                        item.children = flatAsyncRoutes(item.children, [], item.path)
                    }
                })
                return routes
            }
        },
        actions: {
            // 根据权限动态生成路由（前端生成）
            generateRoutesAtFront(asyncRoutes) {
                // eslint-disable-next-line no-async-promise-executor
                return new Promise(async resolve => {
                    const settingsStore = useSettingsStore()
                    const userStore = useUserStore()
                    console.log(asyncRoutes)
                    let accessedRoutes
                    // 如果权限功能开启，则需要对路由数据进行筛选过滤
                    if (settingsStore.app.enablePermission) {
                        const permissions = await userStore.getPermissions()
                        accessedRoutes = filterAsyncRoutes(asyncRoutes, permissions)
                    } else {
                        accessedRoutes = deepClone(asyncRoutes)
                    }
                    console.log('accessedRoutes,', accessedRoutes)
                    // 设置 routes 数据
                    this.isGenerate = true
                    this.routes = accessedRoutes.filter(item => item.children.length != 0)
                    console.log('this.routes', this.routes)
                    resolve()
                })
            },
            // 根据权限动态生成路由（后端获取）
            generateRoutesAtBack() {
                return new Promise(resolve => {
                    api.get('/p/user/userInfo').then(async res => {
                        const settingsStore = useSettingsStore()
                        const userStore = useUserStore()
                        let newAuthority = res.authority.filter(item => {
                            return item.pid == 0 && item.hidden == 0
                        })
                        // console.log('newAuthority', res.authority)
                        newAuthority.forEach(item => {
                            item.children = []
                            res.authority.forEach(val => {
                                if (item.id === val.pid && val.hidden == 0 && val.url) {
                                    item.children.push(val)
                                }
                            })
                            item.children.sort(compare)
                        })
                        newAuthority.sort(compare)
                        console.log('后端获取路由', newAuthority)
                        const routers = [
                            {
                                children: routersFilter(newAuthority),
                                meta: {
                                    icon: 'sidebar-default',
                                    title: '演示'
                                }
                            }
                        ]
                        // console.log('最终整理路由', routers)
                        console.log('系统路由', asyncRoutes)
                        let accessedRoutes
                        // 如果权限功能开启，则需要对路由数据进行筛选过滤
                        if (settingsStore.app.enablePermission) {
                            const permissions = await userStore.getPermissions()
                            accessedRoutes = filterAsyncRoutes(routers, permissions)
                        } else {
                            accessedRoutes = deepClone(asyncRoutes)
                        }
                        console.log('执行了么?', accessedRoutes)
                        // 设置 routes 数据
                        this.isGenerate = true
                        this.routes = accessedRoutes.filter(item => item.children.length != 0)
                        console.log('执行了,额?', this.routes)
                        resolve()
                    })
                })
            },
            // 根据权限动态生成路由（文件系统生成）
            generateRoutesAtFilesystem(asyncRoutes) {
                // eslint-disable-next-line no-async-promise-executor
                return new Promise(async resolve => {
                    const settingsStore = useSettingsStore()
                    const userStore = useUserStore()
                    let accessedRoutes
                    // 如果权限功能开启，则需要对路由数据进行筛选过滤
                    if (settingsStore.app.enablePermission) {
                        const permissions = await userStore.getPermissions()
                        accessedRoutes = filterAsyncRoutes(asyncRoutes, permissions)
                    } else {
                        accessedRoutes = deepClone(asyncRoutes)
                    }
                    // 设置 routes 数据
                    this.isGenerate = true
                    this.routes = accessedRoutes.filter(item => item.children.length != 0)
                    resolve()
                })
            },
            // 记录 accessRoutes 路由，用于登出时删除路由
            setCurrentRemoveRoutes(routes) {
                this.currentRemoveRoutes = routes
            },
            // 清空路由
            removeRoutes() {
                this.isGenerate = false
                this.routes = []
                this.currentRemoveRoutes.forEach(removeRoute => {
                    removeRoute()
                })
                this.currentRemoveRoutes = []
            }
        }
    }
)

export default useRouteStore
