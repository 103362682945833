import ddApi from '@/util/ddApi.js'
import qwApi from '@/util/qwApi.js'
import { getPlatform } from '@/util/system.js'
import { ElMessage } from 'element-plus'
// import useUserStore from '@/store/modules/user'
// const userStore = useUserStore()
// import storage from '@/util/storage'
let memberIs = 'DingTalk'
if (navigator.userAgent.indexOf('DingTalk') == -1) {
    memberIs = 'DingTalk'
}
console.log('navigator', navigator.userAgent.indexOf('DingTalk'))
var ua = window.navigator.userAgent.toLowerCase()
// 判断ua和微信浏览器的标识头是否匹配
if ((ua.match(/micromessenger/i) == 'micromessenger') && (ua.match(/wxwork/i) == 'wxwork')) {
    memberIs = 'jwxwork'
}
export { memberIs }
const obj = {
    AuthConfig: item => { // config注入
        if (memberIs === 'DingTalk') {
            ddApi.AuthConfig(item)
        } else if (memberIs === 'jwxwork') {
            qwApi.AuthConfig(item)
        }
    },
    contact: (...arg) => { // pc选择企业内部联系人，（用于：添加关怀人员）
        if (memberIs === 'DingTalk') {
            return  ddApi.contact_choose(...arg)
        } else if (memberIs === 'jwxwork') {
            return qwApi.selectEnterpriseContact(...arg)
        }
    },
    pickConversation_contact: (...arg) => { // pc选择企业内部联系人，（用于：分享）
        console.log('memberIs', memberIs)
        if (memberIs === 'DingTalk') {
            return  ddApi.chat_pickConversation(...arg)
        } else if (memberIs === 'jwxwork') {
            return qwApi.selectEnterpriseContact(...arg)
        }
    }
}
// export const MB = {
//     AuthConfig: item => { // config注入
//         if (memberIs === 'DingTalk') {
//             ddApi.AuthConfig(item)
//         } else if (memberIs === 'jwxwork') {
//             qwApi.AuthConfig(item)
//         }
//     },
//     customContact: (...arg) => {
//         if (memberIs === 'DingTalk') {
//             ddApi.customContact_choose(...arg)
//         } else if (memberIs === 'jwxwork') {
//             return qwApi.selectEnterpriseContact(...arg)
//         }
//     }
// }
export const MB = new Proxy(obj, {
    get(target, key, receiver) {
        const platform = getPlatform()
        if (platform === 'SAAS_HR' && memberIs !== 'DingTalk') {
            ElMessage.warning('请在钉钉客户端环境下使用')
            return () => Promise.reject('err')
        }
        if (platform === 'qywx_pc' && memberIs !== 'jwxwork') {
            ElMessage.warning('请在企业微信客户端环境下使用')
            return () => Promise.reject('err')
        }
        return target[key]
    }
})
