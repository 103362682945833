import api from '@/api'
import api_ls from '@/api/index1'
import storage from '@/util/storage'
import routers from '@/util/routers'
import { memberIs, MB } from '@/util/memberApi'
import useRouteStore from './route'
import useMenuStore from './menu'
import { getPlatform } from '@/util/index'
import dd from 'dingtalk-jsapi' // 此方式为整体加载，也可按需进行加载

const useUserStore = defineStore(
    // 唯一ID
    'user',
    {
        state: () => ({
            memberApi: memberIs,
            account: storage.local.get('account') || '',
            token: storage.session.get('token') || '',
            avatar: storage.local.get('avatar') || '',
            liShiToken: storage.session.get('liShiToken') || '',
            failure_time: storage.local.get('failure_time') || '',
            permissions: [],
            corpId: storage.local.get('corpId') || '',
            isExpire: 0,
            surplusDays: 0,
            type: 0, // 1欠费用户2付费用户0管理员,
            version: storage.session.get('version') || 'FREE', // FREE 免费版 ;TRIAL 试用版;PROFESSIONAL 专业版
            isPersonal: storage.session.get('isPersonal') || 'N', // 是否是个人版
            platform: storage.session.get('platform') || 'SAAS_HR', // 钉钉SaaS端  qywx_pc: 企业微信pc端
            isConfig: false,
            pc_token: '' // 点击客服跳转用的token
        }),
        getters: {
            isLogin: state => {
                let retn = false
                if (state.token) {
                    retn = true
                }
                return retn
            },
            isAdmin: state =>  {
                let admin = false
                if (storage.session.get('admin') == 1) {
                    admin = true
                }
                return admin
            }
        },
        actions: {
            // 获取版本信息/设置版本信息
            versionValue(type, data) {
                if (type === 'get') {
                    return this.version
                } else if (type === 'set') {
                    this.version = data
                } else {
                    return false
                }
            },
            setkey(key, data) {
                this[key] = data
            },
            // 获取用户信息
            setUserInfo(obj) {
                // this.isExpire = obj.isExpired
                this.isExpire = 0
                this.type = obj.type
                this.surplusDays = obj.surplusDays
            },
            login(data) {
                return new Promise((resolve, reject) => {
                    // 通过 mock 进行登录
                    api.get('/p/user/userInfo', data).then(res => {
                        console.log('res111', res)
                        this.setUserData(res)
                        resolve()
                    }).catch(error => {
                        reject(error)
                    })
                })
            },
            // 钉钉
            authLoginDingDing(code) {
                return new Promise((resolve, reject) => {
                    api.post('/social/dingtalk/silent/login', {
                        code: code,
                        authCorpId: this.corpId
                    }).then(res => {
                        console.log(res)
                        const routeStore = useRouteStore()
                        routeStore.removeRoutes()
                        this.token = res.accessToken
                        storage.session.set('token', res.accessToken)
                        // this.setUserData(res.data)
                        this.getUserInfo()
                        resolve()
                    }).catch(error => {
                        reject(error)
                    })
                })
            },
            // 钉钉应用管理后台
            authBackendLoginDingDing(code) {
                return new Promise((resolve, reject) => {
                    api.get('thirdApi/loginBackend', {
                        params: {
                            code: code
                        }
                    }).then(res => {
                        res.data.platform = 'SAAS_HR'
                        this.setUserData(res.data)
                        resolve()
                    }).catch(error => {
                        reject(error)
                    })
                })
            },
            // 微信授权
            authBackendLoginQW(code) {
                return new Promise((resolve, reject) => {
                    api.get('/thirdApi/enterpriseWeChat/login', {
                        params: {
                            code
                        }
                    }).then(res => {
                        res.data.platform = 'qywx_pc'
                        this.setUserData(res.data)
                        resolve()
                    }).catch(error => {
                        reject(error)
                    })
                })
            },
            getUserInfo() {
                console.log(333)
                return new Promise((resolve, reject) => {
                    // 通过 mock 进行登录
                    api.get('/p/user/userInfo').then(res => {
                        res.platform = 'SAAS_HR'
                        this.setUserData(res)
                        resolve()
                    }).catch(error => {
                        reject(error)
                    })
                })
            },
            // 保存数据
            setUserData(data) {
                console.log(444)
                storage.local.set('account', data.nickName)
                storage.local.set('avatar', data.pic)
                storage.session.set('admin', data.isAdmin)
                // storage.session.set('liShiToken', data.liShiToken)
                storage.session.set('platform', data.platform)
                storage.session.set('corpId', data.bizCorpId || '')
                this.account = data.nickName
                this.avatar = data.pic
                this.platform = data.platform
                // this.liShiToken = data.liShiToken
                this.corpId = data.bizCorpId || ''
                this.ddAuthConfig()
                this.getuserPCToken()
                // this.getPermissions()
            },
            // 保存企业ID
            setCorpId(corpId) {
                storage.local.set('corpId', corpId)
                this.corpId = corpId
            },

            logout() {
                return new Promise(resolve => {
                    const routeStore = useRouteStore()
                    const menuStore = useMenuStore()
                    storage.local.remove('account')
                    storage.session.remove('token')
                    storage.local.remove('failure_time')
                    this.account = ''
                    this.token = ''
                    this.failure_time = ''
                    routeStore.removeRoutes()
                    menuStore.setActived(0)
                    resolve()
                })
            },
            // 获取我的权限
            getPermissions() {
                console.log('获取我的权限')
                return new Promise(resolve => {
                    // 通过 mock 获取权限
                    api.get('/p/user/userInfo').then(res => {
                        // console.log('res1111', res)
                        this.permissions = res.authority.map(item => {
                            return item.model
                        })
                        if (!this.isConfig) { // 未鉴权重新鉴权
                            this.ddAuthConfig()
                            this.getuserPCToken()
                        }
                        resolve(this.permissions)
                    })
                })
            },
            getuserPCToken() {
                api.get('/p/user/getUserPcToken', {
                }).then(res => {
                    this.pc_token = res.accessToken
                })
            },
            // 钉钉鉴权
            async ddAuthConfig() {
                this.isConfig = true
                console.log('开始准备调用鉴权接口')
                if (!this.corpId) {
                    console.log('第一次进来没有corpId')
                    return
                }
                const authTypeMap = {
                    'DingTalk': 1,
                    'jwxwork': 2
                }
                api.get('social/jsApiAuth', {
                    params: {
                        url: window.location.href.split('#')[0],
                        corpId: this.corpId,
                        authType: authTypeMap[memberIs]
                    }
                }).then(res => {
                    console.log(res)
                    console.log('去鉴权,========')
                    console.log(res, '======接口拿到的数据')
                    MB.AuthConfig(res) // 去鉴权
                    // dd.config({
                    //     agentId: res.data.agentId, // 必填，微应用ID
                    //     corpId: res.data.corpId, // 必填，企业ID
                    //     timeStamp: res.data.timeStamp, // 必填，生成签名的时间戳
                    //     nonceStr: res.data.nonceStr, // 必填，自定义固定字符串。
                    //     signature: res.data.signature, // 必填，签名
                    //     jsApiList: [
                    //         'biz.contact.choose',
                    //         'biz.contact.complexPicker',
                    //         'biz.chat.pickConversation',
                    //         'biz.customContact.choose'
                    //     ] // 必填，需要使用的jsapi列表，注意：不要带dd。
                    // })

                    // dd.error(function(err) {
                    //     Proxy.$message.error(JSON.stringify(err))
                    // })
                    // // 该方法必须带上，用来捕获鉴权出现的异常信息，否则不方便排查出现的问题
                })
            },
            editPassword(data) {
                return new Promise(resolve => {
                    api.post('member/edit/password', {
                        account: this.account,
                        password: data.password,
                        newpassword: data.newpassword
                    }, {
                        baseURL: '/mock/'
                    }).then(() => {
                        resolve()
                    })
                })
            }
        }
    }
)

export default useUserStore
