
// 在项目的util文件夹下新建htmlToPdf.js
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'
import { ElLoading } from 'element-plus'
let loading
export default function htmlToPdf(app) {
    app.config.globalProperties.getPdf = function(pdfTitle) {
        loading = ElLoading.service({
            lock: true,
            text: '汇报方案生成中...',
            background: 'rgba(0,0,0,0.3)'
        })
        return new Promise(resolve => {

            html2Canvas(document.querySelector('#pdfDom'), {
                allowTaint: true,
                useCORS: true,
                dpi: window.devicePixelRatio, // 提升导出文件的分辨率
                scale: 4 // 提升导出文件的分辨率

            }).then(function(canvas) {
                loading.close()
                let contentWidth = canvas.width
                let contentHeight = canvas.height
                let pageHeight = (contentWidth / 592.28) * 841.89
                let leftHeight = contentHeight
                let position = 0
                let imgWidth = 595.28
                let imgHeight = (592.28 / contentWidth) * contentHeight
                let pageData = canvas.toDataURL('image/jpeg', 1.0)
                let PDF = new JsPDF('', 'pt', 'a4')
                if (leftHeight < pageHeight) {
                    PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
                } else {
                    while (leftHeight > 0) {
                        PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
                        leftHeight -= pageHeight
                        position -= 841.89
                        if (leftHeight > 0) {
                            PDF.addPage()
                        }
                    }
                }
                PDF.save(pdfTitle + '.pdf')

                // 删除本地存储的base64字段
                var pdfData = PDF.output('datauristring')// 获取base64Pdf
                resolve(pdfData)
            })

        })
    }
}
