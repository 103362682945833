const Layout = () => import('@/layout/index.vue')

export default {
    path: '/care_template',
    component: Layout,
    redirect: '/care/template/plan/list',
    name: 'CareTemplate',
    meta: {
        title: '账户中心',
        icon: 'sidebar-care-template'
    },
    children: [
        {
            path: 'plan/list',
            name: 'CareTemplatePlanList',
            component: () => import('@/views/care_template/care.plan.vue'),
            meta: {
                title: '计划中心',
                notice: true,
                cache: ['CarePlanTemplateAdd', 'CarePlanTemplateEdit', 'CarePlanEditDetail', 'CarePlanDetail', 'CarePlanDetailEdit']
            },
            children: [
                // {
                //     name: '',
                //     path: '',
                //     component: () => import('@/views/care_template/care.plan.vue'),
                //     meta: {
                //         title: '年度计划',
                //         sidebar: false,
                //         breadcrumb: false,
                //         activeMenu: '/care/template',
                //         cache: ['CarePlanTemplateAdd', 'CarePlanTemplateEdit', 'CarePlanEditDetail', 'CarePlanDetail', 'CarePlanDetailEdit']
                //     }
                // },
                {
                    path: 'add',
                    name: 'CarePlanTemplateAdd',
                    component: () => import('@/views/care_template/care.template.add.vue'),
                    meta: {
                        title: '创建计划',
                        sidebar: false,
                        activeMenu: '/care/template/plan/list',
                        back: true
                    }
                },
                {
                    path: 'edit',
                    name: 'CarePlanTemplateEdit',
                    component: () => import('@/views/care_template/care.template.add.vue'),
                    meta: {
                        title: '编辑关怀计划',
                        sidebar: false,
                        activeMenu: '/care/template/plan/list',
                        back: true
                    }
                },

                {
                    path: 'edit/detail',
                    name: 'CarePlanEditDetail',
                    component: () => import('@/views/care_template/care.edit.plan.vue'),
                    meta: {
                        title: '编辑计划详情',
                        sidebar: false,
                        activeMenu: '/care/template/plan/list',
                        back: true
                    }
                },
                {
                    path: 'detail',
                    name: 'CarePlanDetail',
                    component: () => import('@/views/care_template/care.plan.detail.vue'),
                    meta: {
                        title: '计划详情',
                        sidebar: false,
                        activeMenu: '/care/template/plan/list',
                        back: true
                    }
                },
                {
                    path: 'CarePlan/edit',
                    name: 'CarePlanDetailEdit',
                    component: () => import('@/views/care_template/care.plan.unified.edit.vue'),
                    meta: {
                        title: '编辑计划',
                        sidebar: false,
                        activeMenu: '/care/template/list',
                        back: true,
                        cache: 'CarePlanAuditVote'
                    }
                },
                {
                    path: 'audit',
                    name: 'CarePlanAuditVote',
                    component: () => import('@/views/care_template/care.plan.audit.vote.vue'),
                    meta: {
                        title: '审批环节',
                        sidebar: false,
                        activeMenu: '/care/template/list',
                        back: true
                    }
                }
            ]
        },
        {
            path: 'account',
            name: 'AccountIndex',
            redirect: 'care_template/account/index',
            meta: {
                title: '账户中心'
            },
            children: [
                {
                    path: '',
                    name: '',
                    component: () => import('@/views/care_template/account/index.vue'),
                    meta: {
                        title: '账户中心',
                        sidebar: false,
                        breadcrumb: false,
                        activeMenu: '/care/template/account'
                    }
                },
                {
                    path: 'heilBean',
                    name: 'AccountHeilBean',
                    component: () => import('@/views/care_template/account/heilBean.vue'),
                    meta: {
                        title: '余额充值',
                        sidebar: false,
                        // breadcrumb: false,
                        activeMenu: '/care/template/account'
                    }
                }
            ]
        }

    ]
}
