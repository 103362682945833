const Layout = () => import('@/layout/index.vue')

export default {
    path: '/add_care',
    component: Layout,
    name: 'addCareIndex',
    redirect: '/add_care/addCareIndex',
    meta: {
        title: '系统设置',
        icon: 'sidebar-tool',
        auth: ['type.add']
    },
    children: [
        {
            path: 'addCareIndex',
            name: 'addCareIndex',
            component: () => import('@/views/add_care/index.vue'),
            meta: {
                title: '新增关怀',
                activeMenu: '/add_care/addCareIndex',
                auth: ['type.add']
            }
        },
        {
            path: 'approvalSetting',
            name: 'approvalSetting',
            component: () => import('@/views/approvalSetting/index.vue'),
            meta: {
                title: '审批设置',
                activeMenu: '/add_care/approvalSetting',
                icon: 'sidebar-set',
                notice: true,
                auth: ['system.config.approval']
            }
        }
    ]
}
