import * as dd from 'dingtalk-jsapi'
import { ElMessage } from 'element-plus'
function dd_ready(fn) {
    dd.ready(function() {
        return true
    })
    dd.error(function(err) {
        ElMessage('调用失败', err)
        return false
    })
}
const ddApi = {
    // 鉴权/注入
    AuthConfig: item => {
        dd.config({
            agentId: item.agentId, // 必填，微应用ID
            corpId: item.corpId, // 必填，企业ID
            timeStamp: item.timeStamp, // 必填，生成签名的时间戳
            nonceStr: item.nonceStr, // 必填，自定义固定字符串。
            signature: item.signature, // 必填，签名
            jsApiList: [
                'biz.contact.choose',
                'biz.contact.complexPicker',
                'biz.chat.pickConversation',
                'biz.customContact.choose',
                'biz.customContact.multipleChoose'
            ] // 必填，需要使用的jsapi列表，注意：不要带dd。
        })
        dd.error(function(err) {
            Proxy.$message.error(JSON.stringify(err))
        })
    },
    contact_choose: item => {
        // pc选择企业内部的人
        return new Promise((resolve, reject) => {
            // if (dd_ready()) {
            //     console.log('==========4444444444=========')
            //
            // }
            dd.biz.contact.choose({
                // pc选择企业内部的人
                multiple: item.multiple, // 是否多选：true多选 false单选； 默认true
                corpId: item.corpId, // 企业id
                max: item.max || 100, // 人数限制，当multiple为true才生效，可选范围1-1500
                users: item.users || [],
                onSuccess: function(res) {
                    resolve({
                        result: res,
                        type: 'SAAS_HR'
                    })
                },
                onFail: function(err) {
                    reject(err)
                }
            })
            console.log(dd_ready(), '==============dd_ready================')
        })
    },
    chat_pickConversation: item => {
        return new Promise((resolve, reject) => {
            dd.biz.chat.pickConversation({
                // 单选自定义联系人
                corpId: item.corpId, // 标题选择审批人
                isConfirm: item.isConfirm,
                onSuccess: function(res) {
                    resolve({
                        result: res,
                        type: 'SAAS_HR'
                    })
                },
                onFail: function(err) {
                    reject(err)
                }
            })
        })
    }
}
export default ddApi
