const Layout = () => import('@/layout/index.vue')

export default {
    path: '/home',
    component: Layout,
    redirect: '/home/index',
    name: 'Home',
    meta: {
        title: '首页',
        icon: 'home'
    },
    children: [
        {
            path: 'index',
            name: 'HomeIndex',
            redirect: '/home/index',
            component: () => import('@/views/home/list.vue'),
            meta: {
                title: '首页',
                sidebar: false,
                activeMenu: '/home',
                breadcrumb: false,
                notice: false
            },
            children: [
                {
                    name: '',
                    path: '',
                    component: () => import('@/views/home/index.vue'),
                    meta: {
                        title: '首页',
                        sidebar: false,
                        breadcrumb: false,
                        activeMenu: '/home',
                        notice: false
                    }
                },
                {
                    path: 'home/care',
                    name: 'HomeCareEdit',
                    component: () => import('@/views/care_template/care.plan.unified.edit.vue'),
                    meta: {
                        title: '去关怀',
                        sidebar: false,
                        activeMenu: '/home',
                        back: true,
                        cache: ['CarePlanAuditVote']
                    }
                }
            ]
        }
    ]
}
