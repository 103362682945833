const Layout = () => import('@/layout/index.vue')

export default {
    path: '/purchase_order',
    component: Layout,
    redirect: '/purchase/supplier/list',
    name: 'PurchaseOrder',
    meta: {
        title: '企业嗨采',
        icon: 'sidebar-care-order'
    },
    children: [
        {
            path: 'supplier/list',
            name: 'PurchaseSupplierList',
            redirect: '/purchase/supplier/list',
            meta: {
                title: '企业嗨采'
            },
            children: [
                {
                    path: '',
                    name: 'PurchaseSupplierList',
                    component: () => import('@/views/purchase_order/supplier/list.vue'),
                    meta: {
                        title: '企业嗨采',
                        sidebar: false,
                        breadcrumb: false,
                        activeMenu: '/purchase_order/supplier/list'
                    }
                },
                {
                    path: 'detail',
                    name: 'PurchaseSupplierDetail',
                    component: () => import('@/views/purchase_order/supplier/detail.vue'),
                    meta: {
                        title: '商品详情',
                        sidebar: false,
                        activeMenu: '/purchase_order/supplier/list',
                        back: true
                    }
                },
                {
                    path: 'secdetail',
                    name: 'PurchaseSupplierSecdetail',
                    component: () => import('@/views/purchase_order/supplier/secdetail.vue'),
                    meta: {
                        title: '商品详情',
                        sidebar: false,
                        activeMenu: '/purchase_order/supplier/list',
                        back: true
                    }
                },
                {
                    path: 'commit',
                    name: 'PurchaseSupplierCommit',
                    component: () => import('@/views/purchase_order/supplier/orderCommit.vue'),
                    meta: {
                        title: '订单确认',
                        sidebar: false,
                        activeMenu: '/purchase_order/supplier/list',
                        back: true
                    }
                },
                {
                    path: 'success',
                    name: 'PurchasePaySuccess',
                    component: () => import('@/views/purchase_order/supplier/paySuccess.vue'),
                    meta: {
                        title: '支付成功',
                        sidebar: false,
                        activeMenu: '/purchase_order/supplier/list',
                        back: true
                    }
                },
                {
                    path: 'payment',
                    name: 'PurchasePayment',
                    component: () => import('@/views/purchase_order/supplier/payment.vue'),
                    meta: {
                        title: '提交订单',
                        sidebar: false,
                        activeMenu: '/purchase_order/supplier/list',
                        back: true
                    }
                }
            ]
        },
        {
            path: 'cart/list',
            name: 'PurchaseCartList',
            redirect: '/purchase/cart/list',
            meta: {
                title: '购物车'
            },
            children: [
                {
                    path: '',
                    component: () => import('@/views/purchase_order/cart.list.vue'),
                    meta: {
                        title: '',
                        sidebar: false,
                        activeMenu: '/purchase_order/cart/list',
                        breadcrumb: false,
                        notice: true
                    }
                },
                {
                    path: 'commit',
                    name: 'CartListCommit',
                    component: () => import('@/views/purchase_order/orderCommit.vue'),
                    meta: {
                        title: '购物车',
                        sidebar: false,
                        activeMenu: '/purchase_order/cart/list',
                        notice: true
                    }
                }
            ]
        },
        {
            path: 'order/list',
            name: 'PurchaseOrderList',
            redirect: '/purchase/order/list',
            meta: {
                title: '订单'
            },
            children: [
                {
                    name: 'PurchaseOrderList',
                    path: '',
                    component: () => import('@/views/purchase_order/order/list.vue'),
                    meta: {
                        title: '订单',
                        notice: true,
                        sidebar: false,
                        breadcrumb: false,
                        activeMenu: '/purchase_order/order/list'
                    }
                },
                {
                    path: 'detail',
                    name: 'PurchaseOrderDetail',
                    component: () => import('@/views/purchase_order/order/order-detail.vue'),
                    meta: {
                        title: '订单详情',
                        sidebar: false,
                        activeMenu: '/purchase_order/order/list',
                        back: true
                    }
                },
                {
                    path: 'detail/invoice',
                    name: 'InvoiceOrderDetail',
                    component: () => import('@/views/purchase_order/order/invoice-detail.vue'),
                    meta: {
                        title: '发票详情',
                        sidebar: false,
                        activeMenu: '/purchase_order/order/list',
                        back: true
                    }
                },
                {
                    path: 'detail/return',
                    name: 'ReturnOrderDetail',
                    component: () => import('@/views/purchase_order/order/return-detail.vue'),
                    meta: {
                        title: '退款详情',
                        sidebar: false,
                        activeMenu: '/purchase_order/order/list',
                        back: true
                    }
                },
                {
                    path: 'package',
                    name: 'PurchaseOrderPackage',
                    component: () => import('@/views/purchase_order/purchase.package.vue'),
                    meta: {
                        title: '采购套餐',
                        sidebar: false,
                        back: true
                    }
                },
                {
                    path: 'address',
                    name: 'PurchaseOrderPackageAddress',
                    component: () => import('@/views/purchase_order/purchase.select.address.vue'),
                    meta: {
                        title: '选择收货地址',
                        sidebar: false,
                        activeMenu: '/purchase/order/list',
                        back: true
                    }
                },
                {
                    path: 'pay',
                    name: 'PurchaseOrderPay',
                    component: () => import('@/views/purchase_order/order.pay.vue'),
                    meta: {
                        title: '支付',
                        sidebar: false,
                        activeMenu: '/purchase/order/list',
                        back: true
                    }
                }
            ]
        }
    ]
}
