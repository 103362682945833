const Layout = () => import('@/layout/index.vue')

export default {
    path: '/staffCare',
    component: Layout,
    redirect: '/staffCare/birthdayList',
    name: 'staffCare',
    meta: {
        title: '员工关怀',
        icon: 'sidebar-care-type'
    },
    children: [
        {
            path: 'birthdayList',
            name: 'birthdayList',
            component: () => import('@/views/staffCare/birthday/index.vue'),
            meta: {
                title: '生日祝福'
            },
            children: [
                {
                    path: 'birthdayAdd',
                    name: 'birthdayAdd',
                    component: () => import('@/views/care_template/care.plan.unified.edit.vue'),
                    meta: {
                        title: '去关怀',
                        sidebar: false,
                        activeMenu: '/staffCare/birthdayList'
                    }
                },
                {
                    path: 'edit',
                    name: 'birthdayEdit',
                    component: () => import('@/views/add_care/index.vue'),
                    meta: {
                        title: '编辑关怀',
                        sidebar: false,
                        activeMenu: '/staffCare/birthdayList'
                    }
                }
            ]
        },
        {
            path: 'holiday',
            name: 'holiday',
            component: () => import('@/views/staffCare/holiday/index.vue'),
            meta: {
                title: '节日祝福'
            },
            children: [
                {
                    path: 'edit',
                    name: 'holidayEdit',
                    component: () => import('@/views/add_care/index.vue'),
                    meta: {
                        title: '编辑关怀',
                        sidebar: false,
                        activeMenu: '/staffCare/holiday'
                    }
                },
                {
                    path: 'holidayAdd',
                    name: 'holidayAdd',
                    component: () => import('@/views/care_template/care.plan.unified.edit.vue'),
                    meta: {
                        title: '去关怀',
                        sidebar: false,
                        activeMenu: '/staffCare/holiday'
                    }
                }
            ]
        },
        {
            path: 'enterprise',
            name: 'enterprise',
            component: () => import('@/views/staffCare/enterprise/index.vue'),
            meta: {
                title: '企业事件'
            },
            children: [
                {
                    path: 'edit',
                    name: 'enterpriseEdit',
                    component: () => import('@/views/add_care/index.vue'),
                    meta: {
                        title: '编辑关怀',
                        sidebar: false,
                        activeMenu: '/staffCare/enterprise'
                    }
                },
                {
                    path: 'enterpriseAdd',
                    name: 'enterpriseAdd',
                    component: () => import('@/views/care_template/care.plan.unified.edit.vue'),
                    meta: {
                        title: '去关怀',
                        sidebar: false,
                        activeMenu: '/staffCare/enterprise'
                    }
                }
            ]
        },
        {
            path: 'activity',
            name: 'activity',
            component: () => import('@/views/staffCare/activity/index.vue'),
            meta: {
                title: '活动通知'
            },
            children: [
                {
                    path: 'edit',
                    name: 'activityEdit',
                    component: () => import('@/views/add_care/index.vue'),
                    meta: {
                        title: '编辑关怀',
                        sidebar: false,
                        activeMenu: '/staffCare/activity'
                    }
                },
                {
                    path: 'activityAdd',
                    name: 'activityAdd',
                    component: () => import('@/views/care_template/care.plan.unified.edit.vue'),
                    meta: {
                        title: '去关怀',
                        sidebar: false,
                        activeMenu: '/staffCare/activity'
                    }
                }
            ]
        },
        {
            path: 'club',
            name: 'club',
            component: () => import('@/views/staffCare/club/index.vue'),
            meta: {
                title: '俱乐部通知'
            },
            children: [
                {
                    path: 'edit',
                    name: 'clubEdit',
                    component: () => import('@/views/add_care/index.vue'),
                    meta: {
                        title: '编辑关怀',
                        sidebar: false,
                        activeMenu: '/staffCare/club'
                    }
                },
                {
                    path: 'clubAdd',
                    name: 'clubAdd',
                    component: () => import('@/views/care_template/care.plan.unified.edit.vue'),
                    meta: {
                        title: '去关怀',
                        sidebar: false,
                        activeMenu: '/staffCare/club'
                    }
                }
            ]
        }
    ]
}
